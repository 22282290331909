<template>
    <div class="container">
        <div class="container_body">
            <header-component :status="true" :active="activeName"></header-component>
            <div class="products_Banner">
                <img :src="abloutCompany.BannerUrl" alt="" srcset="">
            </div>
            <div class="contents w_100 box_size">
                <div class="content_tabs">
                    <el-tabs v-model="activeName" @tab-click="handleClick" class="contents-el-tabs">
                        <el-tab-pane label="公司简介" name="0"></el-tab-pane>
                        <el-tab-pane label="企业文化" name="1"></el-tab-pane>
                        <el-tab-pane label="发展历程" name="2"></el-tab-pane>
                        <el-tab-pane label="招纳贤才" name="3"></el-tab-pane>
                        <el-tab-pane label="联系我们" name="4"></el-tab-pane>
                    </el-tabs>
                </div>
                <div class="tabsList_body" v-if="activeName == '0'">
                    <div class="tabsList displayBetweenCenterwrap" style="background-color: #fff;">
                        <div class="items">
                            <div class="about_title">
                                <h2> {{ abloutCompany.title }}</h2>
                                <div v-html="abloutCompany.title1" class="about_p"></div>
                            </div>
                            <div class="about_summary w_100 displayBetweenCenter" style="margin: 70px auto 90px auto;">
                                <div class="about_summary_items displaycolumnCenter"
                                    v-for="(items, index) in abloutCompany.data1" :key="index">
                                    <el-image :src="items.imgUrl"></el-image>
                                    <div v-html="items.name" class="t_center"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tabsList displayBetweenCenterwrap" style="background-color: #f6f6f6;">
                        <div class="items">
                            <div class="about_title">
                                <h2> {{ abloutCompany.title2 }}</h2>
                                <div class="about_p t_center">{{ abloutCompany.title21 }}</div>
                            </div>
                            <div class="about_summary w_100 displayBetween" style="margin: 70px auto 90px auto;">
                                <div class="about_summary_itemss" v-for="(items, index) in abloutCompany.data22"
                                    :key="index">
                                    <el-image :src="items.imgUrl"></el-image>
                                    <div class="t_center ">{{ items.name }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tabsList displayBetweenCenterwrap" style="background-color: #fff;">
                        <div class="items">
                            <div class="about_title">
                                <h2> {{ abloutCompany.title3 }}</h2>
                                <div class="about_p t_center">{{ abloutCompany.title31 }}</div>
                            </div>
                            <div class="about_summary w_100 displayBetween" style="margin: 70px auto 90px auto;">
                                <div class="w_80 displayBetween wrap" style="width: 66.6%;">
                                    <div class="about_summary_itemsss w_49" v-for="(items, index) in abloutCompany.data32"
                                        :key="index" v-if="index < 4">
                                        <el-image :src="items.imgUrl" class="w_100" style="height: 217px;"></el-image>
                                    </div>
                                </div>
                                <el-image :src="abloutCompany.data32[4].imgUrl"
                                    style="width: 33.3%;height: 438px "></el-image>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tabsList_body " id="ct" v-if="activeName == '1'">
                    <div class="tabsList displayBetweenCenterwrap mtb40 ">
                        <div class="items displayCenterCenter">
                            <div class="about_summary w_70 displaycolumnAround h250">
                                <div class="about_summary_itemsss" v-for="(items, index) in abloutCompany.data"
                                    :key="index">
                                    <div class="size14 wx_555">{{ items.name }}</div>
                                </div>
                            </div>
                            <el-image :src="abloutCompany.imgUrl" style="width: 350px;height: 350px;"></el-image>
                        </div>
                    </div>
                </div>
                <div class="tabsList_body " id="ct" v-if="activeName == '2'">
                    <div class="tabsList mt40 mb50">
                        <div class="items">
                            <div class="about_title">
                                <div v-html="abloutCompany.name" class="about_p"></div>
                            </div>
                            <p class="slogan">{{ abloutCompany.title }}</p>
                            <div class="wk_about_hty">
                                <div class="line"></div>
                                <div class="item" v-for="(items, index) in abloutCompany.data" :key="index"
                                    :class="index % 2 == 0 ? 'odd' : ''">
                                    <div class="date">{{ items.date }}</div>
                                    <div class="desc"><span class="arrow"></span>{{ items.desc }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tabsList_body" v-if="activeName == '3'">
                    <div class="tabsList displaycolumnCenter">
                        <div class="items">
                            <div class="about_title">
                                <h2> {{ abloutCompany.title }}</h2>
                                <div class="about_p t_center">{{ abloutCompany.name }}</div>
                            </div>
                            <div class="about_summary w_100 displayBetween mt40">
                                <div class="w_80 displayBetween w_100">
                                    <div class="about_summary_itemsss week_job" v-for="(items, index) in abloutCompany.data"
                                        :key="index">
                                        <h2>{{ items.title }}</h2>
                                        <p class="p_name">{{ items.name }}</p>
                                        <div class="content">
                                            <p v-for="(itemss, index) in items.data" :key="index">{{ itemss.name }}</p>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                        <div class="bottom">
                            <p>简历请投至</p>
                            <a href="http://mailto:hr@entfly.com" target="_blank"
                                rel="noopener noreferrer">hr@entfly.com</a>
                        </div>
                    </div>
                </div>
                <div class="tabsList_body" v-if="activeName == '4'">
                    <div class="tabsList">
                        <div class="items">
                            <ul class="contact-ways">
                                <li v-for="(items, index) in abloutCompany.data" :key="index">
                                    <a :href="index == 0 ? 'tel://18210505634' : index == 1 ? 'https://wpa.qq.com/msgrd?v=3&uin=496012146&site=qq&menu=yes' : items.herf"
                                        target="_blank">
                                        <i :class="items.imgUrl"></i>
                                        <h4>{{ items.name }}</h4>
                                        <p class="cway-txt">{{ items.Count }}</p>
                                    </a>
                                </li>
                            </ul>

                        </div>
                        <div class="week_weixin">
                            <div class="week_container">
                                <h3>联系我们</h3>
                                <p>扫一扫二维码</p>
                                <p class="week_weixin-p">
                                    <img src="http://www.entfly.com/template/jiatian_jiaohu/images//weixin.jpg" alt=""
                                        srcset="">
                                </p>
                            </div>
                        </div>
                        <div class="week_map">
                            <baidu-map class="map" :center="center" ak="162987759423e5c2af1bdc11c0f879e1" :zoom="zoom" @ready="handler" >
                                <bm-marker
                                    :position="ThisGps"
                                    :dragging="true"
                                    animation="BMAP_ANIMATION_BOUNCE"
                                />
                                <bm-info-window :position="ThisGps" :width="250" :height="70" 
                                :show="infoWindow.show" @close="infoWindowClose" @open="infoWindowOpen">
                                <p v-text="infoWindow.contents"></p>
                                <p v-text="infoWindow.mobile"></p>
                                </bm-info-window>
                            </baidu-map>
                            <div class="mark-info">
                                <h4 v-text="infoWindow.contents"></h4>
                                <p v-text="infoWindow.mobile1"></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <footerComponent></footerComponent>
        </div>
    </div>
</template>
<script>
import headerComponent from '../../components/comFrom/Header.vue'
import footerComponent from '../../components/comFrom/footer.vue'
import parmasData from '../../public/static/abloutCompany.json'
import BaiduMap from 'vue-baidu-map/components/map/Map.vue'
import BmMarker from "vue-baidu-map/components/overlays/Marker";
import BmInfowindow from "vue-baidu-map/components/overlays/InfoWindow";
export default {
    name: 'products',
    components: {
		BaiduMap,
        BmMarker,
        BmInfowindow
	},
    data() {
        return {
            BannerUrl: 'http://www.entfly.com/template/jiatian_jiaohu/images/bn6.jpg',
            activeName: '0',
            abloutCompany: [],
            center: { lng: 0, lat: 0 },
            ThisGps:{lng: 0, lat: 0 },
			zoom: 0,
            infoWindow: {
                show: true,
                contents: '北京市海淀区丰智东路13号A2栋',
                mobile:"电话：18210505634",
                mobile1:"mobile:18210505634"
            }
        }
    },
    components: {
        headerComponent,
        footerComponent
    },
    created() {
        this.activeName = this.$route.query.index
        this.abloutCompany = parmasData.data[this.$route.query.index]
    },
    methods: {
        handleClick(tab, event) {
            console.log(tab, event)
            this.activeName = tab._props.name;
            this.abloutCompany = parmasData.data[tab._props.name]
        },
        routerLink(index){
            console.log(index,'indexssss')
            this.activeName = index;
            this.abloutCompany = parmasData.data[index]
        },
        handler({ BMap, map }) {
			console.log(BMap, map)
			this.center.lng = 116.255113
			this.center.lat = 40.073117
            this.ThisGps.lng = 116.255113
			this.ThisGps.lat = 40.073117
			this.zoom = 15
		},
        infoWindowClose(e) {
        this.infoWindow.show = false
        },
        infoWindowOpen(e) {
        this.infoWindow.show = true
        },
        isEvenNumber(num) {
            return num % 2 === 0; // 判断是否为偶数
        }
    }
}
</script>
<style scoped>
.container {
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    flex-direction: column;
    overflow: hidden;
}

.container_body {
    width: 100%;
    height: auto;
    overflow-y: scroll;
}

.products_Banner {
    width: 100%;
    min-width: 1180px;
    height: 300px;
    overflow: hidden;
    margin-top: 60px;
}

.products_Banner img {
    width: 100%;
    height: 100%;
}


.content_tabs {
    width: 100%;
    height: 65px;
    background: url(http://www.entfly.com/template/jiatian_jiaohu/images//about_line.jpg) repeat-x left bottom;
    overflow: hidden;
}

.contents-el-tabs {
    width: 500px;
    height: 65px;
    line-height: 65px;
    margin: 0 auto;
    font-size: 16px;
}

.tabsList_body {
    width: 100%;
}

.tabsList {
    width: 100%;
}

.tabsList .items {
    width: 1180px;
    margin: 0 auto;
}

.about_title h2 {
    font-size: 28px;
    color: #333;
    font-weight: normal;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 30px;
}

.about_summary_items,
.about_summary_itemss {
    width: 31%;
}

.about_summary_itemss div {
    font-size: 14px;
    margin: 10px 15px 0;
    overflow: hidden;
    color: #333;
    line-height: 26px;
    text-align: justify;
}

.about_summary_itemss div {
    font-size: 14px;
    margin: 0;
    overflow: hidden;
    color: #333;
    line-height: 26px;
    text-align: justify;
}

.slogan {
    margin-bottom: 30px;
    height: 100px;
    line-height: 100px;
    text-align: center;
    font-size: 26px;
    color: #b8b8b8;
}

#ct {
    min-height: 300px;
    padding-bottom: 10px;
    background: url(http://www.entfly.com/template/jiatian_jiaohu/images//portal_ft.jpg) center bottom no-repeat;
}

.wk_about_hty {
    width: 960px;
    margin: 0 auto;
    position: relative;
    padding: 46px 0;
}

.wk_about_hty .line {
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -1px;
    width: 3px;
    height: 100%;
    background-color: #d4d4d4;
}

.wk_about_hty .item {
    position: relative;
    height: 90px;
}

.wk_about_hty .item:before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -7px;
    width: 12px;
    height: 12px;
    border: 2px solid #DE262E;
    background-color: #fff;
    border-radius: 50%;
}

.wk_about_hty .item .desc {
    position: absolute;
    top: -18px;
    left: 50%;
    padding: 10px 20px;
    margin-left: 38px;
    width: 45%;
    line-height: 30px;
    background-color: #f9f9f9;
    border: 1px solid #eae9e9;
    border-radius: 4px;
    font-size: 14px;
    color: #666;
}

.wk_about_hty .item .desc .arrow {
    position: absolute;
    top: 15px;
    left: -20px;
    border-style: solid;
    border-width: 9px 10px;
    border-color: transparent #eae9e9 transparent transparent;
}

.wk_about_hty .item .desc .arrow:after {
    content: '';
    position: absolute;
    top: -9px;
    left: -8px;
    border-style: solid;
    border-width: 9px 10px;
    border-color: transparent #f9f9f9 transparent transparent;
}

.wk_about_hty .item .date {
    position: absolute;
    top: -6px;
    left: 50%;
    margin-left: -110px;
    width: 78px;
    height: 26px;
    line-height: 26px;
    text-align: center;
    font-size: 12px;
    color: #fff;
    background-color: #DE262E;
}

.wk_about_hty .item .date:after {
    content: '';
    position: absolute;
    top: 9px;
    right: -8px;
    border-style: solid;
    border-width: 5px 4px;
    border-color: transparent transparent transparent #DE262E;
}

.wk_about_hty .item.odd .desc {
    left: auto;
    right: 50%;
    margin: 0 36px 0 0;
    text-align: right;
}

.wk_about_hty .item.odd .desc .arrow {
    left: 100%;
    border-color: transparent transparent transparent #eae9e9;
}

.wk_about_hty .item.odd .desc .arrow:after {
    left: -12px;
    border-color: transparent transparent transparent #f9f9f9;
}

.wk_about_hty .item.odd .date {
    margin-left: 31px;
}

.wk_about_hty .item.odd .date:after {
    right: 100%;
    border-color: transparent #DE262E transparent transparent;
}

.week_job h2 {
    font-size: 20px;
    margin-top: 25px;
    font-weight: normal;
}

.week_job .p_name {
    font-size: 14px;
    display: inline-block;
    margin-top: 20px;
}

.content p {
    width: 315px;
    color: #333;
    line-height: 26px;
    font-size: 12px;
}

.bottom {
    text-align: center;
    margin: 65px auto;
    font-size: 20px;
}

.bottom p {
    font-size: 20px;
}

.bottom a {
    background: #ee302f;
    color: #FFF;
    font-size: 24px;
    display: inline-block;
    line-height: 50px;
    padding: 0 40px;
    margin-top: 15px;
    -moz-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    border-radius: 2px;
}


.contact-ways {
    overflow: hidden;
}

.contact-ways li {
    float: left;
    width: 33%;
    height: 420px;
    border-left: 1px solid #E9E9E9;
    list-style: none;
}

.contact-ways li:last-child {
    border-right: 1px solid #E9E9E9;
}

.contact-ways li a,
.contact-ways li a:hover {
    display: block;
    padding: 88px 0;
    text-align: center;
    text-decoration: none;
}

.contact-ways li h4 {
    font-size: 16px;
    color: #666;
    line-height: 30px;
    margin: 0;
    font-weight: 100;
}

.contact-ways li p.cway-txt {
    font-size: 30px;
    line-height: 50px;
    color: #121212;
}

.contact-ways li:hover p.cway-txt {
    color: #DE262E;
}

.contact-ways li a i {
    margin: 0 auto 20px;
    display: block;
    width: 128px;
    height: 128px;
    background-color: #f8f8f8;
    border-radius: 64px;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.contact-ways li a:hover i {
    background-color: #DE262E;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.contact-ways li a i.i-phone {
    background-image: url(http://www.entfly.com/template/jiatian_jiaohu/images//about/icon_phone.png);
    background-size: 128px;
    background-repeat: no-repeat;
    background-position: 0 0;
}

.contact-ways li a:hover i.i-phone {
    background-position: 0 -128px;
}

.contact-ways li a i.i-qq {
    background-image: url(http://www.entfly.com/template/jiatian_jiaohu/images//about/icon_qq.png);
    background-size: 128px;
    background-repeat: no-repeat;
    background-position: 0 0;
}

.contact-ways li a:hover i.i-qq {
    background-position: 0 -128px;
}

.contact-ways li a i.i-mail {
    background-image: url(http://www.entfly.com/template/jiatian_jiaohu/images//about/icon_mail.png);
    background-size: 128px;
    background-repeat: no-repeat;
    background-position: 0 0;
}

.contact-ways li a:hover i.i-mail {
    background-position: 0 -128px;
}

.week_weixin {
    background: url(http://www.entfly.com/template/jiatian_jiaohu/images//about/bg_weixin.jpg) no-repeat center;
    background-size: cover;
    background-attachment: fixed;
    padding: 80px 0;
}

.week_weixin h3 {
    margin: 0 auto;
    font-size: 32px;
    width: 60%;
    line-height: 1.75;
    text-align: center;
    color: #FFF;
    font-weight: normal;
}

.week_weixin p {
    margin: 0 auto 20px;
    font-size: 16px;
    width: 80%;
    line-height: 1.75;
    text-align: center;
    color: #eee;
}

.week_weixin-p img {
    display: block;
    margin: 0 auto;
    width: 160px;
    height: 160px;
}

.week_weixin p span {
    display: inline-block;
    margin: 0 20px;
}
.week_map {
    position: relative;
    height: 520px;
    min-width: 1024px;
    margin-bottom: -5px;
}
.week_map .map{
    height: 520px;
}
.mark-info {
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
    text-align: center;
    padding: 25px 0;
    background: url(http://www.entfly.com/template/jiatian_jiaohu/images//about/tmbg.png) repeat;
    color: #FFF;
}

.mark-info h4 {
    margin: 0;
    line-height: 36px;
    font-size: 26px;
    font-weight: normal;
}

.mark-info p {
    font-size: 16px;
    line-height: 32px;
}

</style>
<style>
.about_summary_items div p {
    margin-top: 10px;

}

.about_p p {
    margin: 0px auto;
    overflow: hidden;
    line-height: 30px;
    font-size: 14px;
    color: #333;
    text-indent: 2em;
}

.el-tabs__nav-wrap::after {
    background: transparent !important;
}

.el-tabs__item {
    font-size: 16px !important;
}

.el-tabs__item.is-active {
    color: #DE262E !important;
}

.el-tabs__active-bar {
    background-color: #DE262E !important;
}

.el-tabs__item:hover {
    color: #DE262E !important;
}
</style>