<template>
    <div class="container">
        <header-component></header-component>
        <div class="swiper" ref="swiper">
            <div class="swiper-wrapper">
                <div class="swiper-slide">
                    <div class="wk_slide-wrap page1">
                        <ul>
                            <li id="wk_s1" :class="currentIndex == '1' && selected == 1 ? 'wk_selected' : ''">
                                <div class="wk_banner"></div>
                                <div class="wk_wrap">
                                    <div class="wk_banner13">
                                        <img src="http://www.entfly.com/template/jiatian_jiaohu/images/portal/banner13.png"
                                            alt="" srcset="">
                                    </div>
                                    <div class="wk_banner12">
                                        <img src="http://www.entfly.com/template/jiatian_jiaohu/images/portal/banner12.png"
                                            alt="" srcset="">
                                    </div>
                                    <div class="wk_banner14">
                                        <img src="http://www.entfly.com/template/jiatian_jiaohu/images/portal/banner14.png"
                                            alt="" srcset="">
                                    </div>
                                </div>
                            </li>、
                            <li id="wk_s2" :class="selected == 2 ? 'wk_selected' : ''">
                                <div class="wk_banner"></div>
                                <div class="wk_wrap">
                                    <div class="wk_banner22"><img
                                            src="http://www.entfly.com/template/jiatian_jiaohu/images/portal/banner22.png"
                                            alt="" /></div>
                                    <div class="wk_banner23"><img
                                            src="http://www.entfly.com/template/jiatian_jiaohu/images/portal/banner23.png"
                                            alt="" /></div>
                                    <div class="wk_banner24"><a href="#" target="_blank">了解更多</a></div>
                                </div>
                            </li>
                            <li id="wk_s3" :class="selected == 3 ? 'wk_selected' : ''">
                                <div class="wk_banner"></div>
                                <div class="wk_wrap">
                                    <div class="wk_banner32"><img
                                            src="http://www.entfly.com/template/jiatian_jiaohu/images/portal/banner32.png"
                                            alt="" /></div>
                                    <div class="wk_banner33"><img
                                            src="http://www.entfly.com/template/jiatian_jiaohu/images/portal/banner33.png"
                                            alt="" /></div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="wk_arrow"><a href="#service"><img
                                src="http://www.entfly.com/template/jiatian_jiaohu/images/portal/ico3.png" /></a></div>
                    <div class="wk_slide-nav-wrap">
                        <div id="wk_slide-nav">
                            <ul>
                                <li class="wk_nav-line"></li>
                                <li class="wk_nav-bullet-container active" data-index="0"><a class="nav-link"
                                        href="javascript:void(0)">
                                        <div class="nav-bullet"></div>
                                        <div class="nav-text">一品一码,全程溯源</div>
                                    </a></li>
                                <li class="wk_nav-line"></li>
                                <li class="wk_nav-bullet-container" data-index="1"><a class="nav-link"
                                        href="javascript:void(0)">
                                        <div class="nav-bullet"></div>
                                        <div class="nav-text">质量追溯,防伪防窜</div>
                                    </a></li>
                                <li class="wk_nav-line"></li>
                                <li class="wk_nav-bullet-container" data-index="2"><a class="nav-link"
                                        href="javascript:void(0)">
                                        <div class="nav-bullet"></div>
                                        <div class="nav-text">全网控价,品牌维权</div>
                                    </a></li>
                                <li class="wk_nav-line"></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="swiper-slide">
                    <div class="page2 section fp-section active" :class="currentIndex == '2' ? 'active' : ''">
                        <div class="wk_fwxm">
                            <ul>
                                <li class="wk_li1">
                                    <div class="wk_fwxm_bg"></div>
                                    <div class="wk_fwxm_main">
                                        <div class="wk_fwxm_ico"></div>
                                        <h2>产品全程溯源</h2>
                                        <p>Product Process Traceability</p>
                                        <div class="wk_fwxm_des">
                                            基于"一品一码+物联网"技术，为企业提供二维码标签印刷、质量溯源、产品防伪防窜、会员积分、营销推广等服务。
                                        </div>
                                        <div class="wk_ljzx"><a href="http://www.entfly.com/products/powertms/"
                                                target="_blank">立即咨询</a></div>
                                    </div>
                                </li>
                                <li class="wk_li2">
                                    <div class="wk_fwxm_bg"></div>
                                    <div class="wk_fwxm_main">
                                        <div class="wk_fwxm_ico"></div>
                                        <h2>全网价格管控</h2>
                                        <p>Price Control Rights Protection</p>
                                        <div class="wk_fwxm_des">基于"软件监测+人工排查"方式，为企业提供全网电商价格管控，全面打击线上低价乱价行为，保障品牌方价盘稳定。</div>
                                        <div class="wk_ljzx"><a href="http://kj.entfly.com/" target="_blank">立即咨询</a></div>
                                    </div>
                                </li>
                                <li class="wk_li3">
                                    <div class="wk_fwxm_bg"></div>
                                    <div class="wk_fwxm_main">
                                        <div class="wk_fwxm_ico"></div>
                                        <h2>新媒体营销</h2>
                                        <p>New Media Marketing</p>
                                        <div class="wk_fwxm_des">基于"微信社群 + 网红直播"等新媒体工具，运用大数据技术精准推广传播，快速帮助企业实现利润倍增，高客流量 +
                                            高转化率 + 低成本。</div>
                                        <div class="wk_ljzx"><a href="http://wkb.entfly.com/" target="_blank">立即咨询</a></div>
                                    </div>
                                </li>
                                <li class="wk_li4">
                                    <div class="wk_fwxm_bg"></div>
                                    <div class="wk_fwxm_main">
                                        <div class="wk_fwxm_ico"></div>
                                        <h2>大客户专业定制</h2>
                                        <p>Key Account Customization</p>
                                        <div class="wk_fwxm_des">
                                            基于助力软件多年行业经验，为大客户企业提供定制化服务，包括微信小程序开发、网站建设、软件开发及外包、网络推广、电商代运营等服务。</div>
                                        <div class="wk_ljzx"><a href="http://www.entfly.com/products/powertms/"
                                                target="_blank">立即咨询</a></div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="swiper-slide">
                    <div class="section page3" style="height: 100%;" :class="currentIndex == '3' ? 'active' : ''">
                        <div class="case">
                            <h2><img src="http://www.entfly.com/template/jiatian_jiaohu/images/portal/case_title.png"
                                    alt="" /></h2>
                            <h2 class="wk_h2"><img
                                    src="http://www.entfly.com/template/jiatian_jiaohu/images/portal/case_title1.png"
                                    alt="" />
                            </h2>
                            <div class="wk_btndiv">
                                <a class="abtn aleft " :class="agrayIndex == 1 ? 'agrayleft' : ''"
                                    href="javascript:void(0);" @click="agrayClick(1)"></a>
                                <a class="abtn aright" :class="agrayIndex == 2 ? 'agrayright' : ''"
                                    href="javascript:void(0);" @click="agrayClick(2)"></a>
                            </div>
                            <div class="wk_scrollcontainer">
                                <ul :style="{ left: agrayIndex == 1 ? '0' : '-1180px' }" style="transition: all .5s">
                                    <li>
                                        <div class="wk_case_list"><a
                                                href="http://www.entfly.com/articles/solutions/sy/201701/00000047.html"
                                                target="_blank">
                                                <div class="wk_case_img"><img
                                                        src="http://www.entfly.com/data/attachment/block/e4/e4d6364b3d4bd3b9a332e6b9fc7511e0.jpg"
                                                        width="275" height="190" /></div>
                                                <div class="wk_case_ico"></div>
                                                <div class="wk_case_xian"><span class="wk_span_1"></span><span
                                                        class="wk_span_2"></span><span class="wk_span_3"></span><span
                                                        class="wk_span_4"></span></div>
                                            </a></div>
                                        <div class="wk_case_list"><a
                                                href="http://www.entfly.com/articles/solutions/sy/201701/00000046.html"
                                                target="_blank">
                                                <div class="wk_case_img"><img
                                                        src="http://www.entfly.com/data/attachment/block/21/21b8c7635d8edd9389b7a68c2d637e57.jpg"
                                                        width="275" height="190" /></div>
                                                <div class="wk_case_ico"></div>
                                                <div class="wk_case_xian"><span class="wk_span_1"></span><span
                                                        class="wk_span_2"></span><span class="wk_span_3"></span><span
                                                        class="wk_span_4"></span></div>
                                            </a></div>
                                        <div class="wk_case_list"><a
                                                href="http://www.entfly.com/articles/solutions/sy/201611/00000022.html"
                                                target="_blank">
                                                <div class="wk_case_img"><img
                                                        src="http://www.entfly.com/data/attachment/block/da/da2c9d4db1d6e25af6a83bbe6b9e6959.jpg"
                                                        width="275" height="190" /></div>
                                                <div class="wk_case_ico"></div>
                                                <div class="wk_case_xian"><span class="wk_span_1"></span><span
                                                        class="wk_span_2"></span><span class="wk_span_3"></span><span
                                                        class="wk_span_4"></span></div>
                                            </a></div>
                                        <div class="wk_case_list"><a
                                                href="http://www.entfly.com/articles/solutions/sy/201611/00000021.html"
                                                target="_blank">
                                                <div class="wk_case_img"><img
                                                        src="http://www.entfly.com/data/attachment/block/eb/eb80f1d0599f693bcc20ea6ec1d8832c.jpg"
                                                        width="275" height="190" /></div>
                                                <div class="wk_case_ico"></div>
                                                <div class="wk_case_xian"><span class="wk_span_1"></span><span
                                                        class="wk_span_2"></span><span class="wk_span_3"></span><span
                                                        class="wk_span_4"></span></div>
                                            </a></div>
                                        <div class="wk_case_list"><a
                                                href="http://www.entfly.com/articles/solutions/kj/201702/00000065.html"
                                                target="_blank">
                                                <div class="wk_case_img"><img
                                                        src="http://www.entfly.com/data/attachment/block/13/13e24a286819f8a7d1b02fe87fcdbee4.jpg"
                                                        width="275" height="190" /></div>
                                                <div class="wk_case_ico"></div>
                                                <div class="wk_case_xian"><span class="wk_span_1"></span><span
                                                        class="wk_span_2"></span><span class="wk_span_3"></span><span
                                                        class="wk_span_4"></span></div>
                                            </a></div>
                                        <div class="wk_case_list"><a
                                                href="http://www.entfly.com/articles/solutions/sy/201611/00000024.html"
                                                target="_blank">
                                                <div class="wk_case_img"><img
                                                        src="http://www.entfly.com/data/attachment/block/8f/8fcaee86c3207a3bd1d6221f11b76216.jpg"
                                                        width="275" height="190" /></div>
                                                <div class="wk_case_ico"></div>
                                                <div class="wk_case_xian"><span class="wk_span_1"></span><span
                                                        class="wk_span_2"></span><span class="wk_span_3"></span><span
                                                        class="wk_span_4"></span></div>
                                            </a></div>
                                        <div class="wk_case_list"><a
                                                href="http://www.entfly.com/articles/solutions/sy/201511/00000015.html"
                                                target="_blank">
                                                <div class="wk_case_img"><img
                                                        src="http://www.entfly.com/data/attachment/block/1b/1b017771dfdd160a8811dffece0fd3c4.jpg"
                                                        width="275" height="190" /></div>
                                                <div class="wk_case_ico"></div>
                                                <div class="wk_case_xian"><span class="wk_span_1"></span><span
                                                        class="wk_span_2"></span><span class="wk_span_3"></span><span
                                                        class="wk_span_4"></span></div>
                                            </a></div>
                                        <div class="wk_case_list"><a
                                                href="http://www.entfly.com/articles/solutions/sy/201611/00000016.html"
                                                target="_blank">
                                                <div class="wk_case_img"><img
                                                        src="http://www.entfly.com/data/attachment/block/bf/bffb2ee9ac4e4315996a76d6625991f9.jpg"
                                                        width="275" height="190" /></div>
                                                <div class="wk_case_ico"></div>
                                                <div class="wk_case_xian"><span class="wk_span_1"></span><span
                                                        class="wk_span_2"></span><span class="wk_span_3"></span><span
                                                        class="wk_span_4"></span></div>
                                            </a></div>
                                    </li>
                                    <li>
                                        <div class="wk_case_list"><a
                                                href="http://www.entfly.com/articles/solutions/sy/201611/00000023.html"
                                                target="_blank">
                                                <div class="wk_case_img"><img
                                                        src="http://www.entfly.com/data/attachment/block/2b/2b9efa473d6c925fc97e0fc76d27e571.jpg"
                                                        width="275" height="190" /></div>
                                                <div class="wk_case_ico"></div>
                                                <div class="wk_case_xian"><span class="wk_span_1"></span><span
                                                        class="wk_span_2"></span><span class="wk_span_3"></span><span
                                                        class="wk_span_4"></span></div>
                                            </a></div>
                                        <div class="wk_case_list"><a
                                                href="http://www.entfly.com/articles/solutions/sy/201701/00000043.html"
                                                target="_blank">
                                                <div class="wk_case_img"><img
                                                        src="http://www.entfly.com/data/attachment/block/b2/b21b177df18b498c08412cd7a8a12b19.jpg"
                                                        width="275" height="190" /></div>
                                                <div class="wk_case_ico"></div>
                                                <div class="wk_case_xian"><span class="wk_span_1"></span><span
                                                        class="wk_span_2"></span><span class="wk_span_3"></span><span
                                                        class="wk_span_4"></span></div>
                                            </a></div>
                                        <div class="wk_case_list"><a
                                                href="http://www.entfly.com/articles/solutions/sy/201611/00000014.html"
                                                target="_blank">
                                                <div class="wk_case_img"><img
                                                        src="http://www.entfly.com/data/attachment/block/e3/e383fa763978ada702048f8b094595c4.jpg"
                                                        width="275" height="190" /></div>
                                                <div class="wk_case_ico"></div>
                                                <div class="wk_case_xian"><span class="wk_span_1"></span><span
                                                        class="wk_span_2"></span><span class="wk_span_3"></span><span
                                                        class="wk_span_4"></span></div>
                                            </a></div>
                                        <div class="wk_case_list"><a
                                                href="http://www.entfly.com/articles/solutions/sy/201701/00000045.html"
                                                target="_blank">
                                                <div class="wk_case_img"><img
                                                        src="http://www.entfly.com/data/attachment/block/29/297faf7fc444d1b38b9eeddf8dbac99b.jpg"
                                                        width="275" height="190" /></div>
                                                <div class="wk_case_ico"></div>
                                                <div class="wk_case_xian"><span class="wk_span_1"></span><span
                                                        class="wk_span_2"></span><span class="wk_span_3"></span><span
                                                        class="wk_span_4"></span></div>
                                            </a></div>
                                        <div class="wk_case_list"><a
                                                href="http://www.entfly.com/articles/solutions/sy/201701/00000039.html"
                                                target="_blank">
                                                <div class="wk_case_img"><img
                                                        src="http://www.entfly.com/data/attachment/block/90/90b8e43a7dd8f061d3da311ea44b2f0c.jpg"
                                                        width="275" height="190" /></div>
                                                <div class="wk_case_ico"></div>
                                                <div class="wk_case_xian"><span class="wk_span_1"></span><span
                                                        class="wk_span_2"></span><span class="wk_span_3"></span><span
                                                        class="wk_span_4"></span></div>
                                            </a></div>
                                        <div class="wk_case_list"><a
                                                href="http://www.entfly.com/articles/solutions/sy/201701/00000041.html"
                                                target="_blank">
                                                <div class="wk_case_img"><img
                                                        src="http://www.entfly.com/data/attachment/block/69/696492769ca11c191c068ef9b9b1dfd6.jpg"
                                                        width="275" height="190" /></div>
                                                <div class="wk_case_ico"></div>
                                                <div class="wk_case_xian"><span class="wk_span_1"></span><span
                                                        class="wk_span_2"></span><span class="wk_span_3"></span><span
                                                        class="wk_span_4"></span></div>
                                            </a></div>
                                        <div class="wk_case_list"><a
                                                href="http://www.entfly.com/articles/solutions/sy/201701/00000044.html"
                                                target="_blank">
                                                <div class="wk_case_img"><img
                                                        src="http://www.entfly.com/data/attachment/block/cf/cf7fc439554368e508b09607efbaec4e.jpg"
                                                        width="275" height="190" /></div>
                                                <div class="wk_case_ico"></div>
                                                <div class="wk_case_xian"><span class="wk_span_1"></span><span
                                                        class="wk_span_2"></span><span class="wk_span_3"></span><span
                                                        class="wk_span_4"></span></div>
                                            </a></div>
                                        <div class="wk_case_list"><a
                                                href="http://www.entfly.com/articles/solutions/sy/201701/00000042.html"
                                                target="_blank">
                                                <div class="wk_case_img"><img
                                                        src="http://www.entfly.com/data/attachment/block/a4/a4111f02af92478f1937ef5fc215642d.jpg"
                                                        width="275" height="190" /></div>
                                                <div class="wk_case_ico"></div>
                                                <div class="wk_case_xian"><span class="wk_span_1"></span><span
                                                        class="wk_span_2"></span><span class="wk_span_3"></span><span
                                                        class="wk_span_4"></span></div>
                                            </a>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="swiper-slide">
                    <div class="section page4 fp-section" style="height: 100%;" :class="currentIndex == '4' ? 'active' : ''">
                        <div class="wk_about" style="overflow: hidden">
                            <h2><img src="http://www.entfly.com/template/jiatian_jiaohu/images/portal/about_title.png"
                                    alt="" /></h2>
                            <h2 class="wk_h2"><img
                                    src="http://www.entfly.com/template/jiatian_jiaohu/images/portal/about_title1.png"
                                    alt="" />
                            </h2>
                            <div class="wk_about_menu" id="wk_about_menu"><a :class="aboutIndex == 1 ? 'active' : ''"
                                    @mouseover="aboutClick(1)">简介</a><a :class="aboutIndex == 2 ? 'active' : ''"
                                    @mouseover="aboutClick(2)">动态</a><a :class="aboutIndex == 3 ? 'active' : ''"
                                    @mouseover="aboutClick(3)">历程</a>
                            </div>
                            <div class="wk_about_div" id="wk_about_div"
                                :style="{ left: aboutIndex == 1 ? '0' : aboutIndex == 2 ? '-1080px' : '-2160px' }"
                                style="width: 3600px; height: 966px;transition: all .5s">
                                <div class="wk_about_main wk_aaa" style="float: left; width: 1080px; display: block;">
                                    <div class="wk_about_left"><img
                                            src="http://www.entfly.com/template/jiatian_jiaohu/images/portal/about_img.png"
                                            alt="" /></div>
                                    <div class="wk_about_right">
                                        <div class="wk_about_top" style="height: 130px ">
                                            <a><img src="http://www.entfly.com/template/jiatian_jiaohu/images/portal/about_ico1.png"
                                                    alt="" /></a>
                                            <a><img src="http://www.entfly.com/template/jiatian_jiaohu/images/portal/about_ico2.png"
                                                    alt="" /></a>
                                            <a><img src="http://www.entfly.com/template/jiatian_jiaohu/images/portal/about_ico3.png"
                                                    alt="" /></a>
                                            <a style="margin-right:0;"><img
                                                    src="http://www.entfly.com/template/jiatian_jiaohu/images/portal/about_ico4.png"
                                                    alt="" /></a>
                                            <div class="clear"></div>
                                        </div>
                                        <div class="wk_about_des">
                                            　　北京助力软件有限公司是一家基于物联网、移动互联网、大数据及云计算技术，集技术研发、系统集成、产品运营为一体，富有创新性的技术驱动型企业，坚持以技术创新驱动企业的发展。
                                            <div class="wk_about_sec">
                                                　　公司自成立伊始，就以“专注行业核心价值，助力企业扬帆远航”为使命，依托团队丰富的行业经验和信息化研发、集成及项目背景。作为国家级高新技术企业和中关村高新技术企业，公司通过了ISO9001质量体系认证，拥有专利及软件著作权几十项。多年来，公司致力于为企业提供产品全生命周期追溯与运营解决方案，成功服务了包括蒙牛、雅士利、君乐宝、北大荒、完达山、三元等几十家行业领军企业，并获得了用户的高度认可。
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="wk_news_list wk_aaa" style="display: block; float: left; width: 1080px;">
                                    <ul>
                                        <li>
                                            <dl>
                                                <dt>2016-11-19</dt>
                                            </dl>
                                            <h4><a href="articles/xw/gsxw/201611/00000035.html"
                                                    target="_blank">微溯云®质量可追溯云服务平台上线了</a></h4>
                                            <div class="wk_news_des">微溯云®食品农产品质量可追溯云服务平台...</div>
                                            <div class="wk_news_more"><a href="articles/xw/gsxw/201611/00000035.html"
                                                    target="_blank"></a></div>
                                        </li>
                                        <li>
                                            <dl>
                                                <dt>2016-12-28</dt>
                                            </dl>
                                            <h4><a href="articles/xw/mtbt/201612/00000038.html"
                                                    target="_blank">做微商这么久，什么才是最重要的？</a></h4>
                                            <div class="wk_news_des">微信作为一款超级app，俨然已在即时通讯行业一家独大，有用户就有流量，有流量就有生...</div>
                                            <div class="wk_news_more"><a href="articles/xw/mtbt/201612/00000038.html"
                                                    target="_blank"></a></div>
                                        </li>
                                        <li>
                                            <dl>
                                                <dt>2016-10-09</dt>
                                            </dl>
                                            <h4><a href="articles/xw/hyxw/201610/00000036.html"
                                                    target="_blank">如何运用二维码使企业价值最大化</a>
                                            </h4>
                                            <div class="wk_news_des">
                                                随着互联网科技迅速发展，二维码应用已经全面覆盖各行各业，成为企业营销管理的一个趋...</div>
                                            <div class="wk_news_more"><a href="articles/xw/hyxw/201610/00000036.html"
                                                    title="�Ķ�����" target="_blank"></a></div>
                                        </li>
                                        <li>
                                            <dl>
                                                <dt>2017-06-19</dt>
                                            </dl>
                                            <h4><a href="articles/xw/gsxw/201706/00000073.html"
                                                    target="_blank">我公司“助力商城管理系统”（简称PowerSho</a></h4>
                                            <div class="wk_news_des">
                                                热烈祝贺我公司“助力商城管理系统”（简称PowerShop）通过国家版权局审核，正式取得...</div>
                                            <div class="wk_news_more"><a href="articles/xw/gsxw/201706/00000073.html"
                                                    target="_blank"></a></div>
                                        </li>
                                    </ul>
                                </div>
                                <div class="wk_history wk_aaa" style="display: block; float: left; width: 1080px;">
                                    <div class="wk_blk_18">
                                        <div class="wk_pcont" id="ISL_Cont_11">
                                            <div class="ScrCont">
                                                <div id="List1_1">
                                                    <div id="List1_1">
                                                        <div class="wk_fzlc_s">
                                                            <h4>2017年10月</h4>
                                                            <div class="wk_his_ico"></div>
                                                            <dl>
                                                                <dt><img
                                                                        src="http://www.entfly.com//template/jiatian_jiaohu/images/portal/history_img1.jpg">
                                                                </dt>
                                                                <dd>助力软件与蓝带啤酒就二维码营销系统达成合作协议</dd>
                                                            </dl>
                                                        </div>
                                                        <div class="wk_fzlc_b">
                                                            <h4>2016年11月</h4>
                                                            <div class="wk_his_ico"></div>
                                                            <dl>
                                                                <dt><img
                                                                        src="http://www.entfly.com/template/jiatian_jiaohu/images/portal/history_img2.jpg">
                                                                </dt>
                                                                <dd>微溯云平台上线</dd>
                                                            </dl>
                                                        </div>
                                                        <div class="wk_fzlc_s">
                                                            <h4>2016年6月</h4>
                                                            <div class="wk_his_ico"></div>
                                                            <dl>
                                                                <dt><img
                                                                        src="http://www.entfly.com/template/jiatian_jiaohu/images/portal/history_img3.jpg">
                                                                </dt>
                                                                <dd>承接西藏珠峰冰川饮用水可溯源的会员营销系统</dd>
                                                            </dl>
                                                        </div>
                                                        <div class="wk_fzlc_b">
                                                            <h4>2016年1月</h4>
                                                            <div class="wk_his_ico"></div>
                                                            <dl>
                                                                <dt><img
                                                                        src="http://www.entfly.com/template/jiatian_jiaohu/images/portal/history_img4.jpg">
                                                                </dt>
                                                                <dd>与茉莉花企业“闽榕茶业”签订智能营销平台项目合同</dd>
                                                            </dl>
                                                        </div>
                                                        <div class="wk_fzlc_s">
                                                            <h4>2015年11月</h4>
                                                            <div class="wk_his_ico"></div>
                                                            <dl>
                                                                <dt><img
                                                                        src="http://www.entfly.com/template/jiatian_jiaohu/images/portal/history_img5.jpg">
                                                                </dt>
                                                                <dd>荣获国际高新技术企业证书</dd>
                                                            </dl>
                                                        </div>
                                                        <div class="wk_fzlc_b">
                                                            <h4>2015年10月</h4>
                                                            <div class="wk_his_ico"></div>
                                                            <dl>
                                                                <dt>
                                                                    <img
                                                                        src="http://www.entfly.com/template/jiatian_jiaohu/images/portal/history_img6.jpg">
                                                                </dt>
                                                                <dd>完成ISO9001质量认证体系</dd>
                                                            </dl>
                                                        </div>
                                                        <div class="wk_fzlc_s">
                                                            <h4>2015年9月</h4>
                                                            <div class="wk_his_ico"></div>
                                                            <dl>
                                                                <dt>
                                                                    <img
                                                                        src="http://www.entfly.com/template/jiatian_jiaohu/images/portal/history_img7.jpg">
                                                                </dt>
                                                                <dd>成为蒙牛集团创新事业部O2O项目指定供应商</dd>
                                                            </dl>
                                                        </div>
                                                        <div class="wk_fzlc_b">
                                                            <h4>2015年8月</h4>
                                                            <div class="wk_his_ico"></div>
                                                            <dl>
                                                                <dt>
                                                                    <img
                                                                        src="http://www.entfly.com/template/jiatian_jiaohu/images/portal/history_img8.jpg">
                                                                </dt>
                                                                <dd>蒙牛集团奶粉事业部会员管理1期合同验收</dd>
                                                            </dl>
                                                        </div>
                                                        <div class="wk_fzlc_b">
                                                            <h4>2015年5月</h4>
                                                            <div class="wk_his_ico"></div>
                                                            <dl>
                                                                <dt>
                                                                    <img
                                                                        src="http://www.entfly.com/template/jiatian_jiaohu/images/portal/history_img8.jpg">
                                                                </dt>
                                                                <dd>助力软件有限公司成立，业务从原公司剥离，独立运营</dd>
                                                            </dl>
                                                        </div>
                                                        <div class="wk_fzlc_b">
                                                            <h4>2012年3月</h4>
                                                            <div class="wk_his_ico"></div>
                                                            <dl>
                                                                <dt>
                                                                    <img
                                                                        src="http://www.entfly.com/template/jiatian_jiaohu/images/portal/history_img8.jpg">
                                                                </dt>
                                                                <dd>助力软件前身公司成立</dd>
                                                            </dl>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="List2_1"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="c"></div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="swiper-slide">
                    <div class="section page5" style="height: 100%;" :class="currentIndex == '5' ? 'active' : ''">
                        <div class="wk_service">
                            <h2><img src="http://www.entfly.com/template/jiatian_jiaohu/images/portal/service_title.png"
                                    alt="" /></h2>
                            <h2 class="wk_h2"><img
                                    src="http://www.entfly.com/template/jiatian_jiaohu/images/portal/service_title1.png"
                                    alt="" />
                            </h2>
                            <div class="wk_service_xian"></div>
                            <div class="wk_service_main">
                                <ul>
                                    <li><img src="http://www.entfly.com/template/jiatian_jiaohu/images/portal/service_img1.png"
                                            alt="" /><span class="wk_span_1"></span><span class="wk_span_2"></span><span
                                            class="wk_span_3"></span><span class="wk_span_4"></span></li>
                                    <li><img src="http://www.entfly.com/template/jiatian_jiaohu/images/portal/service_img2.png"
                                            alt="" /><span class="wk_span_1"></span><span class="wk_span_2"></span><span
                                            class="wk_span_3"></span><span class="wk_span_4"></span></li>
                                    <li><img src="http://www.entfly.com/template/jiatian_jiaohu/images/portal/service_img3.png"
                                            alt="" /><span class="wk_span_1"></span><span class="wk_span_2"></span><span
                                            class="wk_span_3"></span><span class="wk_span_4"></span></li>
                                    <li><img src="http://www.entfly.com/template/jiatian_jiaohu/images/portal/service_img4.png"
                                            alt="" /><span class="wk_span_1"></span><span class="wk_span_2"></span><span
                                            class="wk_span_3"></span><span class="wk_span_4"></span></li>
                                    <li><img src="http://www.entfly.com/template/jiatian_jiaohu/images/portal/service_img5.png"
                                            alt="" /><span class="wk_span_1"></span><span class="wk_span_2"></span><span
                                            class="wk_span_3"></span><span class="wk_span_4"></span></li>
                                    <li><img src="http://www.entfly.com/template/jiatian_jiaohu/images/portal/service_img6.png"
                                            alt="" /><span class="wk_span_1"></span><span class="wk_span_2"></span><span
                                            class="wk_span_3"></span><span class="wk_span_4"></span></li>
                                    <li><img src="http://www.entfly.com/template/jiatian_jiaohu/images/portal/service_img7.png"
                                            alt="" /><span class="wk_span_1"></span><span class="wk_span_2"></span><span
                                            class="wk_span_3"></span><span class="wk_span_4"></span></li>
                                    <li><img src="http://www.entfly.com/template/jiatian_jiaohu/images/portal/service_img8.png"
                                            alt="" /><span class="wk_span_1"></span><span class="wk_span_2"></span><span
                                            class="wk_span_3"></span><span class="wk_span_4"></span></li>
                                    <li><img src="http://www.entfly.com/template/jiatian_jiaohu/images/portal/service_img9.png"
                                            alt="" /><span class="wk_span_1"></span><span class="wk_span_2"></span><span
                                            class="wk_span_3"></span><span class="wk_span_4"></span></li>
                                    <li><img src="http://www.entfly.com/template/jiatian_jiaohu/images/portal/service_img10.png"
                                            alt="" /><span class="wk_span_1"></span><span class="wk_span_2"></span><span
                                            class="wk_span_3"></span><span class="wk_span_4"></span></li>
                                    <li><img src="http://www.entfly.com/template/jiatian_jiaohu/images/portal/service_img11.png"
                                            alt="" /><span class="wk_span_1"></span><span class="wk_span_2"></span><span
                                            class="wk_span_3"></span><span class="wk_span_4"></span></li>
                                    <li><img src="http://www.entfly.com/template/jiatian_jiaohu/images/portal/service_img12.png"
                                            alt="" /><span class="wk_span_1"></span><span class="wk_span_2"></span><span
                                            class="wk_span_3"></span><span class="wk_span_4"></span></li>
                                    <li><img src="http://www.entfly.com/template/jiatian_jiaohu/images/portal/service_img13.png"
                                            alt="" /><span class="wk_span_1"></span><span class="wk_span_2"></span><span
                                            class="wk_span_3"></span><span class="wk_span_4"></span></li>
                                    <li><img src="http://www.entfly.com/template/jiatian_jiaohu/images/portal/service_img14.png"
                                            alt="" /><span class="wk_span_1"></span><span class="wk_span_2"></span><span
                                            class="wk_span_3"></span><span class="wk_span_4"></span></li>
                                    <li><img src="http://www.entfly.com/template/jiatian_jiaohu/images/portal/service_img15.png"
                                            alt="" /><span class="wk_span_1"></span><span class="wk_span_2"></span><span
                                            class="wk_span_3"></span><span class="wk_span_4"></span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="swiper-slide">
                    <div class="section page6" style="height: 100%;" :class="currentIndex == '6' ? 'active' : ''">
                        <div class="wk_contact">
                            <div class="wk_contact_left"><img
                                    src="http://www.entfly.com/template/jiatian_jiaohu/images/portal/contact_img.png"
                                    alt="" /></div>
                            <div class="wk_contact_left1"><img
                                    src="http://www.entfly.com/template/jiatian_jiaohu/images/portal/contact_img1.png"
                                    alt="" /></div>
                            <div class="wk_contact_right">
                                <h2><img src="http://www.entfly.com/template/jiatian_jiaohu/images/portal/contact_title.png"
                                        alt="" /></h2>
                                <ul class="wk_cont_div">
                                    <li class="wk_serve-ico-kf">业务咨询：<span class="wk_cont_a"><img
                                                src="http://www.entfly.com/template/jiatian_jiaohu/images/portal/cont_a.png" /></span><a
                                            href="http://wpa.qq.com/msgrd?v=3&amp;uin=496012146&amp;site=qq&amp;menu=yes"
                                            target="_blank">496012146 【点击咨询】</a></li>
                                    <li class="wk_serve-ico-kf">技术服务：<span class="wk_cont_a"><img
                                                src="http://www.entfly.com/template/jiatian_jiaohu/images/portal/cont_a.png" /></span><a
                                            href="http://wpa.qq.com/msgrd?v=3&amp;uin=108706021&amp;site=qq&amp;menu=yes"
                                            target="_blank">108706021 【点击咨询】</a></li>
                                    <li><span class="wk_cont_c"></span>vip@entfly.com</li>
                                    <li><span class="wk_cont_d"></span>北京市海淀区丰智东路13号A2栋</li>
                                </ul>
                            </div>
                        </div>
                        <div class="wk_footer_side" style="position: absolute;bottom: 0;left: 0;width:100%">
                            <div class="wk_footer">
                                Copyright &copy; 2016 <a href="http://www.entfly.com" target="_blank">Powersoft Inc.</a> All
                                Rights Reserved. By <a href="http://www.entfly.com" target="_blank">助力软件</a>备案号：<a
                                    href="http://www.miitbeian.gov.cn/" target="_blank">京ICP备16006501号-3</a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <!-- 如果需要分页器 -->
            <div class="swiper-pagination"></div>
        </div>
        <!-- <OnlineConsultation/> -->
    </div>
</template>
   
<script>
// 引入js
import Swiper from "swiper";
// 引入css
import "swiper/css/swiper.min.css";
import headerComponent from '../components/comFrom/Header.vue'
import OnlineConsultation from '../components/comFrom/OnlineConsultation.vue'
export default {
    name:'home',
    components:{
        headerComponent,
        OnlineConsultation
    },
    data() {
        return {
            selected: 1,
            timer: null,
            agrayIndex: 1,
            aboutIndex: 1,
            currentIndex: 1
        }
    },
    mounted() {
        this.getSwiper()
        this.timer = setInterval(() => {
            if (this.selected == '3') {
                this.selected = 1;
            } else {
                this.selected = this.selected + 1
            }
        }, 2000)
    },
    methods: {
        agrayClick(val) {
            this.agrayIndex = val;
        },
        aboutClick(val) {
            this.aboutIndex = val;
        },
        goTop(){
            console.log('进来了')
            // const swiper = new Swiper('.swiper');
            // swiper.slideToLoop(0);
        },
        getSwiper(){
            new Swiper(".swiper", {
            direction: "vertical", // 垂直切换选项
            loop: false, // 循环模式选项
            mousewheel: true, // 是否开启鼠标滚轮控制swiper切换 ，默认false
            // 如果需要分页器
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
                clickableClass: 'my-pagination-clickable',
               
            },
            on: {
                slideChangeTransitionEnd: (e) => {
                    this.currentIndex = this.$refs.swiper.swiper.activeIndex + 1
                },
            },
        });
        }
    }
};
</script>

<style>
.container {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.swiper {
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    overflow: hidden;
    cursor:pointer
}

.swiper-slide {
    transform: translate3d(0px, 0px, 0px);
    transition: all 700ms ease 0s;
    box-sizing: border-box;
}

.swiper-container-vertical>.swiper-pagination-bullets {
    left: 10px;
}

.swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    background-color: #fff !important;
}

.swiper-pagination-bullet-active {
    background: #de262e !important;

}
</style>
<style scoped>
.el-dropdown {
    font-size: 16px;
    color: #666;
}

/* 轮播 */
a:hover {
    text-decoration: inherit;
}

img {
    -ms-interpolation-mode: bicubic;
    border: 0;
    vertical-align: bottom;
    max-width: 100%;
}


.wk_index_main {
    margin-top: -60px;
    width: 100%;
}

#superContainer {
    height: 100%;
    position: relative;
    -ms-touch-action: none;
    touch-action: none;
}

.fp-section {
    position: relative;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.fp-slide {
    float: left;
}

.fp-slide,
.fp-slidesContainer {
    display: block;
    height: 100%;
}

.fp-slides {
    z-index: 1;
    height: 100%;
    overflow: hidden;
    position: relative;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.fp-section.fp-table,
.fp-slide.fp-table {
    display: table;
    width: 100%;
    table-layout: fixed;
}

.fp-tableCell {
    display: table-cell;
    width: 100%;
    height: 100%;
    vertical-align: middle;
}

.fp-slidesContainer {
    position: relative;
    float: left;
}

.fp-controlArrow {
    position: absolute;
    top: 50%;
    z-index: 4;
    margin-top: -38px;
    width: 0;
    height: 0;
    border-style: solid;
    cursor: pointer;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
}

.fp-controlArrow.fp-prev {
    left: 15px;
    width: 0;
    border-color: transparent #fff transparent transparent;
    border-width: 38.5px 34px 38.5px 0;
}

.fp-controlArrow.fp-next {
    right: 15px;
    border-color: transparent transparent transparent #fff;
    border-width: 38.5px 0 38.5px 34px;
}

.fp-scrollable {
    overflow: scroll;
}

.fp-notransition {
    -webkit-transition: none !important;
    transition: none !important;
}

#dn-nav {
    position: fixed;
    top: 50%;
    z-index: 100;
    margin-top: -32px;
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
}

#dn-nav.right {
    left: 17px;
}

#dn-nav ul {
    margin: 0;
    padding: 0;
}

#dn-nav ul li {
    position: relative;
    display: block;
    margin: 7px;
    width: 15px;
    height: 15px;
}

#dn-nav ul li a {
    position: relative;
    z-index: 1;
    display: block;
    width: 100%;
    height: 100%;
    text-decoration: none;
    cursor: pointer;
}

#dn-nav ul li a span {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    margin: -3px 0 0 -3px;
    width: 7px;
    height: 7px;
    border: 0;
    border-radius: 50%;
    background: #cfcfd1;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

#dn-nav ul li a.active span,
#dn-nav ul li:hover a.active span {
    margin: -7px 0 0 -7px;
    width: 15px;
    height: 15px;
    border-radius: 100%;
    background: #de262e;
}

.fp-auto-height .fp-slide,
.fp-auto-height .fp-tableCell,
.fp-auto-height.fp-section {
    height: auto !important;
}

.section {
    position: relative;
    overflow: hidden;
}

.page1 {
    background: #000;
    width: 100%;
}

.wk_slide-wrap {
    position: relative;
    width: 100%;
    height: 100%;
}

.wk_slide-wrap ul {
    width: 100%;
    height: 100%;
}

.wk_slide-wrap li {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
    text-align: center;
}

.wk_slide-wrap li .img {
    position: relative;
    z-index: 9;
    -webkit-transition: all 1s;
    -moz-transition: all 1s;
    transition: all 1s;
    -ms-transition: all 1s;
}

.wk_slide-wrap li.wk_selected .img {
    -webkit-transform: translate3d(0, 0, 0) !important;
    transform: translate3d(0, 0, 0) !important;
    -ms-transform: translate3d(0, 0, 0) !important;
}

.wk_slide-wrap li .text {
    position: relative;
    z-index: 9;
    margin-top: 25px;
    color: #fff;
    font-size: 20px;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    transition: all .5s;
    -ms-transition: all .5s;
}

.wk_slide-wrap .wk_banner {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    height: 100%;
    min-width: 100%;
}

.wk_slide-wrap li#wk_s1 .wk_banner {
    background: url(http://www.entfly.com/template/jiatian_jiaohu/images//portal/cssimg/banner11.jpg) no-repeat center;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    width: 100%;
}

.wk_slide-wrap li#wk_s1.wk_selected .wk_banner {
    opacity: 1;
    -webkit-opacity: 1;
    -ms-opacity: 1;
    -moz-opacity: 1;
}

.wk_slide-wrap li#wk_s1 .wk_wrap {
    position: absolute;
    top: 45%;
    left: 50%;
    margin-top: -175px;
    margin-left: -375px;
    width: 750px;
    height: 350px;
}

.wk_slide-wrap li#wk_s1 .wk_banner12 img {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -5px;
    margin-left: -12px;
    width: 24px;
    height: 11px;
    opacity: 0;
    -webkit-transition: all 1s ease 0s;
    -moz-transition: all 1s ease 0s;
    transition: all 1s ease 0s;
    -ms-transition: all 1s ease 0s;
}

.wk_slide-wrap li#wk_s1.wk_selected .wk_banner12 img {
    margin-top: -137px;
    margin-left: -300px;
    width: 600px;
    height: 275px;
    opacity: 1;
    -webkit-opacity: 1;
    -ms-opacity: 1;
    -moz-opacity: 1;
}

.wk_slide-wrap li#wk_s1 .wk_banner13 {
    position: absolute;
    top: 0;
    left: -125px;
    opacity: 0;
    -webkit-transition: all 1s ease 0s;
    -moz-transition: all 1s ease 0s;
    transition: all 1s ease 0s;
    -webkit-opacity: 0;
    -moz-opacity: 0;
    -ms-transition: all 1s ease 0s;
}

.wk_slide-wrap li#wk_s1.wk_selected .wk_banner13 {
    left: 0;
    opacity: 1;
    -webkit-transition-delay: 800ms;
    -moz-transition-delay: 800ms;
    transition-delay: 800ms;
    -webkit-opacity: 1;
    -ms-opacity: 1;
    -moz-opacity: 1;
}

.wk_slide-wrap li#wk_s1 .wk_banner14 {
    position: absolute;
    top: 0;
    right: -200px;
    opacity: 0;
    -webkit-transition: all 1s ease 0s;
    -moz-transition: all 1s ease 0s;
    transition: all 1s ease 0s;
    -webkit-opacity: 0;
    -moz-opacity: 0;
    -ms-transition: all 1s ease 0s;
}

.wk_slide-wrap li#wk_s1.wk_selected .wk_banner14 {
    right: 0;
    opacity: 1;
    -webkit-transition-delay: 800ms;
    -moz-transition-delay: 800ms;
    transition-delay: 800ms;
    -webkit-opacity: 1;
    -ms-opacity: 1;
    -moz-opacity: 1;
}

.wk_slide-wrap li#wk_s2 .wk_wrap {
    position: absolute;
    top: 45%;
    left: 50%;
    margin-top: -155px;
    margin-left: -372px;
    width: 745px;
    height: 310px;
}

.wk_slide-wrap li#wk_s2 .wk_banner {
    background: url(http://www.entfly.com/template/jiatian_jiaohu/images//portal/cssimg/banner21.jpg) no-repeat center;
    background-size: cover;
    opacity: 0;
    -webkit-transition: all 1s;
    -moz-transition: all 1s;
    -o-transition: all 1s;
    transition: all 1s;
    -webkit-opacity: 0;
    -ms-opacity: 0;
    -moz-opacity: 0;
    -webkit-background-size: cover;
    -moz-background-size: cover;
}

.wk_slide-wrap li#wk_s2.wk_selected .wk_banner {
    opacity: 1;
    -webkit-opacity: 1;
    -ms-opacity: 1;
    -moz-opacity: 1;
}

.wk_slide-wrap li#wk_s2 .wk_banner22 {
    position: absolute;
    top: 0;
    left: -200px;
    opacity: 0;
    -webkit-transition: all 1s ease 0s;
    -moz-transition: all 1s ease 0s;
    transition: all 1s ease 0s;
    -ms-transition: all 1s ease 0s;
}

.wk_slide-wrap li#wk_s2.wk_selected .wk_banner22 {
    left: 0;
    opacity: 1;
    -webkit-opacity: 1;
    -ms-opacity: 1;
    -moz-opacity: 1;
}

.wk_slide-wrap li#wk_s2 .wk_banner23 {
    position: absolute;
    top: 130px;
    right: -150px;
    opacity: 0;
    -webkit-transition: all 1s ease 0s;
    -moz-transition: all 1s ease 0s;
    transition: all 1s ease 0s;
    -ms-transition: all 1s ease 0s;
}

.wk_slide-wrap li#wk_s2.wk_selected .wk_banner23 {
    right: 50px;
    opacity: 1;
    -webkit-opacity: 1;
    -ms-opacity: 1;
    -moz-opacity: 1;
}

.wk_slide-wrap li#wk_s2 .wk_banner24 {
    position: absolute;
    bottom: -200px;
    left: 50%;
    z-index: 10;
    margin-left: -80px;
    width: 160px;
    height: 38px;
    opacity: 0;
    -webkit-transition: all 1s ease 0s;
    -moz-transition: all 1s ease 0s;
    transition: all 1s ease 0s;
    -webkit-opacity: 0;
    -moz-opacity: 0;
    -ms-transition: all 1s ease 0s;
}

.wk_slide-wrap li#wk_s2 .wk_banner24 a {
    display: block;
    width: 158px;
    border: 1px solid #fff;
    color: #fff;
    text-align: center;
    font-size: 17px;
    line-height: 36px;
    cursor: pointer;
}

.wk_slide-wrap li#wk_s2.wk_selected .wk_banner24 {
    bottom: 0;
    opacity: 1;
    -webkit-transition-delay: 600ms;
    -moz-transition-delay: 600ms;
    transition-delay: 600ms;
    -webkit-opacity: 1;
    -moz-opacity: 1;
}

.wk_slide-wrap li#wk_s3 .wk_wrap {
    position: absolute;
    top: 45%;
    left: 50%;
    margin-top: -140px;
    margin-left: -332px;
    width: 665px;
    height: 280px;
}

.wk_slide-wrap li#wk_s3 .wk_banner {
    background: url(http://www.entfly.com/template/jiatian_jiaohu/images//portal/cssimg/banner31.jpg) no-repeat center;
    background-size: cover;
    opacity: 0;
    -webkit-transition: all 1s;
    -moz-transition: all 1s;
    -o-transition: all 1s;
    transition: all 1s;
    -webkit-opacity: 0;
    -ms-opacity: 0;
    -moz-opacity: 0;
    -webkit-background-size: cover;
    -moz-background-size: cover;
}

.wk_slide-wrap li#wk_s3.wk_selected .wk_banner {
    opacity: 1;
    -webkit-opacity: 1;
    -ms-opacity: 1;
    -moz-opacity: 1;
}

.wk_slide-wrap li#wk_s3 .wk_banner32 {
    position: absolute;
    top: -200px;
    left: 50%;
    margin-left: -332px;
    opacity: 0;
    -webkit-transition: all 1s ease 0s;
    -moz-transition: all 1s ease 0s;
    transition: all 1s ease 0s;
    -ms-transition: all 1s ease 0s;
}

.wk_slide-wrap li#wk_s3.wk_selected .wk_banner32 {
    top: 0;
    opacity: 1;
    -webkit-opacity: 1;
    -ms-opacity: 1;
    -moz-opacity: 1;
}

.wk_slide-wrap li#wk_s3 .wk_banner33 {
    position: absolute;
    bottom: -200px;
    left: 50%;
    margin-left: -332px;
    opacity: 0;
    -webkit-transition: all 1s ease 0s;
    -moz-transition: all 1s ease 0s;
    transition: all 1s ease 0s;
    -ms-transition: all 1s ease 0s;
}

.wk_slide-wrap li#wk_s3.wk_selected .wk_banner33 {
    bottom: 0;
    opacity: 1;
    -webkit-opacity: 1;
    -ms-opacity: 1;
    -moz-opacity: 1;
}

.wk_arrow {
    position: absolute;
    bottom: 140px;
    left: 50%;
    z-index: 20;
    display: block;
    margin-left: -44px;
    width: 87px;
    height: 87px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    text-align: center;
    cursor: pointer;
}

.wk_arrow img {
    position: relative;
    top: 26px;
}

.wk_arrow:after,
.wk_arrow:before {
    position: absolute;
    top: 0;
    left: 0;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    content: '';
    pointer-events: none;
}

.wk_arrow:before {
    width: 88px;
    height: 88px;
    border: 1px solid #fff;
    -webkit-transition: all .8s cubic-bezier(.455, .03, .515, .955);
    -moz-transition: all .8s cubic-bezier(.455, .03, .515, .955);
    -o-transition: all .8s cubic-bezier(.455, .03, .515, .955);
    transition: all .8s cubic-bezier(.455, .03, .515, .955);
}

.wk_arrow:after {
    width: 86px;
    height: 86px;
    border: 2px solid #fff;
    background-color: rgba(255, 255, 255, .1);
    opacity: 0;
    -webkit-transition: all .8s cubic-bezier(.77, 0, .175, 1);
    -moz-transition: all .8s cubic-bezier(.77, 0, .175, 1);
    -o-transition: all .8s cubic-bezier(.77, 0, .175, 1);
    transition: all .8s cubic-bezier(.77, 0, .175, 1);
    -webkit-transform: scale(.5, .5);
    -moz-transform: scale(.5, .5);
    transform: scale(.5, .5);
    -ms-transform: scale(.5, .5);
}

.page1 .wk_arrow:hover:before {
    opacity: 0;
    -webkit-transition: all .8s cubic-bezier(.25, .46, .45, .94);
    -moz-transition: all .8s cubic-bezier(.25, .46, .45, .94);
    -o-transition: all .8s cubic-bezier(.25, .46, .45, .94);
    transition: all .8s cubic-bezier(.25, .46, .45, .94);
    -webkit-transform: scale(1.5, 1.5);
    -moz-transform: scale(1.5, 1.5);
    transform: scale(1.5, 1.5);
    -ms-transform: scale(1.5, 1.5);
}

.page1 .wk_arrow:hover:after {
    opacity: 1;
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    transform: scale(1, 1);
    -ms-transform: scale(1, 1);
}

.wk_slide-nav-wrap {
    position: absolute;
    bottom: 35px;
    left: 0;
    z-index: 8;
    overflow: hidden;
    margin-bottom: -22px;
    padding-top: 25px;
    width: 100%;
    height: 84px;
    opacity: 1;
    -webkit-transform: translateY(100%);
    -webkit-transform: matrix(1, 0, 0, 1, 0, 0);
    -moz-transform: translateY(100%);
    -moz-transform: matrix(1, 0, 0, 1, 0, 0);
    transform: translateY(100%);
    transform: matrix(1, 0, 0, 1, 0, 0);
    -webkit-opacity: 1;
    -ms-opacity: 1;
    -moz-opacity: 1;
    -ms-transform: translateY(100%);
    -ms-transform: matrix(1, 0, 0, 1, 0, 0);
}

#wk_slide-nav {
    position: relative;
    z-index: 10;
    margin: auto;
    width: 620px;
    height: 26px;
    -webkit-transform: translateX(90px);
    -moz-transform: translateX(90px);
    transform: translateX(90px);
    -ms-transform: translateX(90px);
}

#wk_slide-nav ul {
    display: flex;
}

.wk_nav-line {
    position: relative;
    top: 13px;
    width: 90px;
    height: 1px;
}

.wk_nav-line:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 90px;
    height: 100%;
    background-color: #fff;
    content: '';
    -webkit-transition: all .8s cubic-bezier(.77, 0, .175, 1);
    -moz-transition: all .8s cubic-bezier(.77, 0, .175, 1);
    -o-transition: all .8s cubic-bezier(.77, 0, .175, 1);
    transition: all .8s cubic-bezier(.77, 0, .175, 1);
}

.wk_nav-line.small:after {
    width: 78px;
}

.wk_nav-line.x-small:after {
    width: 63px;
    -webkit-transition: all 1s cubic-bezier(.77, 0, .175, 1);
    -moz-transition: all 1s cubic-bezier(.77, 0, .175, 1);
    -o-transition: all 1s cubic-bezier(.77, 0, .175, 1);
    transition: all 1s cubic-bezier(.77, 0, .175, 1);
}

.wk_nav-line.prev:after {
    left: 0;
}

.wk_nav-line.next:after {
    right: 0;
}

.wk_nav-line:first-child:before,
.wk_nav-line:last-child:before {
    position: absolute;
    top: 0;
    width: 8000px;
    height: 1px;
    background-color: #fff;
    content: '';
    -moz-backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.wk_nav-line:first-child:before {
    left: -8000px;
}

.wk_nav-line:last-child:before {
    right: -8000px;
}

.wk_nav-bullet-container {
    position: relative;
    width: 26px;
    height: 26px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}

.wk_nav-bullet-container:nth-child(1) {
    margin-left: 100px;
}

.wk_nav-bullet-container:before {
    position: absolute;
    top: -15px;
    left: 8px;
    width: 0;
    height: 0;
    border-color: #fff transparent transparent transparent;
    border-style: solid;
    border-width: 6px 5px 0 5px;
    content: '';
    opacity: 0;
    -webkit-transition: all .8s cubic-bezier(.77, 0, .175, 1);
    -moz-transition: all .8s cubic-bezier(.77, 0, .175, 1);
    -o-transition: all .8s cubic-bezier(.77, 0, .175, 1);
    transition: all .8s cubic-bezier(.77, 0, .175, 1);
    -webkit-transform: translateY(-10px);
    -moz-transform: translateY(-10px);
    transform: translateY(-10px);
    pointer-events: none;
    -ms-transform: translateY(-10px);
    -moz-backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

#wk_slide-nav ul {
    display: flex;
}

#wk_slide-nav .wk_nav-bullet-container:hover:before {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    transform: translateY(0);
    -ms-transform: translateY(0);
}

#wk_slide-nav .wk_nav-bullet-container:hover .nav-link:before {
    opacity: 0;
    -webkit-transition: all .8s cubic-bezier(.25, .46, .45, .94);
    -moz-transition: all .8s cubic-bezier(.25, .46, .45, .94);
    -o-transition: all .8s cubic-bezier(.25, .46, .45, .94);
    transition: all .8s cubic-bezier(.25, .46, .45, .94);
    -webkit-transform: scale(2, 2);
    -moz-transform: scale(2, 2);
    transform: scale(2, 2);
    -ms-transform: scale(2, 2);
}

#wk_slide-nav .wk_nav-bullet-container:hover .nav-link:after {
    opacity: .2;
    -webkit-transform: scale(3.3, 3.3);
    -moz-transform: scale(3.3, 3.3);
    transform: scale(3.3, 3.3);
    -ms-transform: scale(3.3, 3.3);
}

#wk_slide-nav .wk_nav-bullet-container:hover .nav-bullet {
    opacity: 1;
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    transform: scale(1, 1);
    -ms-transform: scale(1, 1);
}

#wk_slide-nav .wk_nav-bullet-container:hover .nav-text {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    transform: translateY(0);
    -ms-transform: translateY(0);
}

.wk_nav-bullet-container.active .nav-link:after {
    opacity: 1;
}

.nav-link {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    color: #fff;
}

.nav-link:before {
    position: absolute;
    top: 5px;
    left: 5px;
    width: 14px;
    height: 14px;
    border: 1px solid #fff;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    content: '';
    opacity: 1;
    -webkit-transition: all .8s cubic-bezier(.455, .03, .515, .955);
    -moz-transition: all .8s cubic-bezier(.455, .03, .515, .955);
    -o-transition: all .8s cubic-bezier(.455, .03, .515, .955);
    transition: all .8s cubic-bezier(.455, .03, .515, .955);
}

.nav-link:after {
    position: absolute;
    top: 8px;
    left: 8px;
    width: 8px;
    height: 8px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    background-color: #fff;
    content: '';
    opacity: 1;
    -webkit-transition: all .8s cubic-bezier(.77, 0, .175, 1);
    -moz-transition: all .8s cubic-bezier(.77, 0, .175, 1);
    -o-transition: all .8s cubic-bezier(.77, 0, .175, 1);
    transition: all .8s cubic-bezier(.77, 0, .175, 1);
    -moz-backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.nav-bullet {
    position: absolute;
    top: 9px;
    left: 9px;
    width: 8px;
    height: 8px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    background-color: #fff;
    opacity: 0;
    -webkit-transition: all .8s cubic-bezier(.77, 0, .175, 1);
    -moz-transition: all .8s cubic-bezier(.77, 0, .175, 1);
    -o-transition: all .8s cubic-bezier(.77, 0, .175, 1);
    transition: all .8s cubic-bezier(.77, 0, .175, 1);
    -webkit-transform: scale(0, 0);
    -moz-transform: scale(0, 0);
    transform: scale(0, 0);
    -ms-transform: scale(0, 0);
}

.nav-text {
    position: absolute;
    bottom: -22px;
    left: -44px;
    width: 113px;
    color: #fff;
    text-align: center;
    font-size: 12px;
    opacity: 0;
    -webkit-transition: all .8s cubic-bezier(.77, 0, .175, 1);
    -moz-transition: all .8s cubic-bezier(.77, 0, .175, 1);
    -o-transition: all .8s cubic-bezier(.77, 0, .175, 1);
    transition: all .8s cubic-bezier(.77, 0, .175, 1);
    -webkit-transform: translateY(10px);
    -moz-transform: translateY(10px);
    transform: translateY(10px);
    pointer-events: none;
    -ms-transform: translateY(10px);
}

.page2 {
    background: url(http://www.entfly.com/template/jiatian_jiaohu/images//portal/cssimg/bg2.jpg) no-repeat center;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
}

.wk_fwxm {
    position: relative;
    margin: 0 auto;
    width: 1200px;
    height: 100vh;
}

.wk_fwxm ul {
    width: 100%;
    height: 100%;
}

.wk_fwxm li {
    position: relative;
    float: left;
    width: 25%;
    height: 100%;
    color: #fff;
    text-align: center;
    opacity: 0;
    -webkit-transition: all 1s;
    -moz-transition: all 1s;
    -o-transition: all 1s;
    transition: all 1s;
    -webkit-opacity: 0;
    -moz-opacity: 0;
}

.wk_fwxm li .wk_fwxm_bg {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 0;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}

.wk_fwxm li.wk_li1 .wk_fwxm_bg {
    background: #ba292e;
}

.wk_fwxm li.wk_li2 .wk_fwxm_bg {
    background: #1599a5;
}

.wk_fwxm li.wk_li3 .wk_fwxm_bg {
    background: #5e904d;
}

.wk_fwxm li.wk_li4 .wk_fwxm_bg {
    background: #5d4378;
}

.wk_fwxm li:hover .wk_fwxm_bg {
    height: 100%;
}

.wk_fwxm li .wk_fwxm_main {
    position: absolute;
    top: 50%;
    right: 25px;
    left: 25px;
    margin-top: -145px;
}

.wk_fwxm li .wk_ljzx {
    margin: 35px auto 0 auto;
    width: 145px;
    height: 35px;
}

.wk_fwxm li .wk_ljzx a {
    display: none;
    width: 143px;
    height: 33px;
    border: 1px solid #fff;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    color: #fff;
    text-align: center;
    font-size: 17px;
    line-height: 33px;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
    -webkit-transition-delay: 400ms;
    -moz-transition-delay: 400ms;
    transition-delay: 400ms;
}

.wk_fwxm li:hover .wk_ljzx a {
    display: block;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}

.wk_fwxm li.wk_li1:hover .wk_ljzx a:hover {
    background: #fff;
    color: #ba292e;
}

.wk_fwxm li.wk_li2:hover .wk_ljzx a:hover {
    background: #fff;
    color: #1599a5;
}

.wk_fwxm li.wk_li3:hover .wk_ljzx a:hover {
    background: #fff;
    color: #5e904d;
}

.wk_fwxm li.wk_li4:hover .wk_ljzx a:hover {
    background: #fff;
    color: #5d4378;
}

.wk_fwxm li .wk_fwxm_ico {
    overflow: hidden;
    margin: 0 auto;
    width: 110px;
    height: 110px;
    -webkit-border-radius: 110px;
    -moz-border-radius: 110px;
    border-radius: 110px;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    transition: all .3s;
    -webkit-transition-delay: 400ms;
    -moz-transition-delay: 400ms;
    transition-delay: 400ms;
}

.wk_fwxm li.wk_li1 .wk_fwxm_ico {
    background: url(http://www.entfly.com/template/jiatian_jiaohu/images//portal/cssimg/fwxm_img1.png) no-repeat;
}

.wk_fwxm li.wk_li1:hover .wk_fwxm_ico {
    background: url(http://www.entfly.com/template/jiatian_jiaohu/images//portal/cssimg/fwxm_img1.png) no-repeat 0 -110px;
}

.wk_fwxm li.wk_li2 .wk_fwxm_ico {
    background: url(http://www.entfly.com/template/jiatian_jiaohu/images//portal/cssimg/fwxm_img2.png) no-repeat;
}

.wk_fwxm li.wk_li2:hover .wk_fwxm_ico {
    background: url(http://www.entfly.com/template/jiatian_jiaohu/images//portal/cssimg/fwxm_img2.png) no-repeat 0 -110px;
}

.wk_fwxm li.wk_li3 .wk_fwxm_ico {
    background: url(http://www.entfly.com/template/jiatian_jiaohu/images//portal/cssimg/fwxm_img3.png) no-repeat;
}

.wk_fwxm li.wk_li3:hover .wk_fwxm_ico {
    background: url(http://www.entfly.com/template/jiatian_jiaohu/images//portal/cssimg/fwxm_img3.png) no-repeat 0 -110px;
}

.wk_fwxm li.wk_li4 .wk_fwxm_ico {
    background: url(http://www.entfly.com/template/jiatian_jiaohu/images//portal/cssimg/fwxm_img4.png) no-repeat;
}

.wk_fwxm li.wk_li4:hover .wk_fwxm_ico {
    background: url(http://www.entfly.com/template/jiatian_jiaohu/images//portal/cssimg/fwxm_img4.png) no-repeat 0 -110px;
}

.wk_fwxm li h2 {
    margin-top: 16px;
    font-weight: 400;
    font-size: 19px;
    line-height: 44px;
}

.wk_fwxm li p {
    font-size: 15px;
    line-height: 30px;
}

.wk_fwxm li .wk_fwxm_des {
    margin-top: 20px;
    font-size: 13px;
    line-height: 26px;
}

.page2 li.wk_li1 {
    -webkit-transform: translate3d(-600px, 0, 0);
    -moz-transform: translate3d(-600px, 0, 0);
    transform: translate3d(-600px, 0, 0);
    -ms-transform: translate3d(-600px, 0, 0);
}

.page2 li.wk_li2 {
    -webkit-transform: translate3d(-300px, 0, 0);
    -moz-transform: translate3d(-300px, 0, 0);
    transform: translate3d(-300px, 0, 0);
    -ms-transform: translate3d(-300px, 0, 0);
}

.page2 li.wk_li3 {
    -webkit-transform: translate3d(300px, 0, 0);
    -moz-transform: translate3d(300px, 0, 0);
    transform: translate3d(300px, 0, 0);
    -ms-transform: translate3d(300px, 0, 0);
}

.page2 li.wk_li4 {
    -webkit-transform: translate3d(600px, 0, 0);
    -moz-transform: translate3d(600px, 0, 0);
    transform: translate3d(600px, 0, 0);
    -ms-transform: translate3d(600px, 0, 0);
}

.page2.active li {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -webkit-opacity: 1;
    -moz-opacity: 1;
}

@media(max-width: 1500px) {
    .wk_fwxm {
        position: relative;
        margin: 0 auto;
        width: 1160px;
        height: 100vh;
    }

    .wk_fwxm li .wk_fwxm_des {
        font-size: 12px;
    }
}

@media(max-width: 1150px) {
    .wk_fwxm {
        position: relative;
        margin: 0 auto;
        width: 1000px;
        height: 100%;
    }

    .wk_fwxm li h2 {
        font-size: 17px;
    }

    .wk_fwxm li .wk_fwxm_main {
        right: 15px;
        left: 15px;
    }

    .wk_fwxm li .wk_fwxm_des {
        font-size: 12px;
    }
}

@media(max-width: 1500px) {
    .section {
        width: 100%;
        min-width: 1080px;
    }

    .wk_slide-wrap li#wk_s1 .wk_wrap {
        top: 50%;
    }

    .wk_slide-wrap li#wk_s2 .wk_wrap {
        top: 50%;
    }

    .wk_slide-wrap li#wk_s2 .wk_banner22 img {
        width: 90%;
    }

    .wk_slide-wrap li#wk_s3 .wk_wrap {
        top: 50%;
    }

    .wk_slide-wrap li#wk_s3 .wk_banner32 img {
        width: 90%;
    }

    .page1 .wk_arrow {
        display: none;
    }

    .wk_slide-nav-wrap {
        bottom: 5px;
    }

}

.page3 {
    background: url(http://www.entfly.com/template/jiatian_jiaohu/images//portal/cssimg/bg3.jpg) no-repeat center;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
}

.case {
    position: absolute;
    top: 50%;
    left: 50%;
    overflow: hidden;
    margin-top: -235px;
    margin-left: -580px;
    width: 1160px;
}

.case h2 {
    position: relative;
    top: 0;
    left: -400px;
    width: auto;
    -webkit-transition: all 1s ease 0s;
    transition: all 1s ease 0s;
    -ms-transition: all 1s ease 0s;
}

.page3.active .case h2 {
    left: 0;
}

.case h2.wk_h2 {
    display: none;
}

.wk_btndiv {
    position: absolute;
    top: 45px;
    right: -400px;
    width: 100px;
    height: 30px;
    -webkit-transition: all 1s ease 0s;
    transition: all 1s ease 0s;
    -ms-transition: all 1s ease 0s;
}

.page3.active .wk_btndiv {
    right: 0;
}

.wk_btndiv a.abtn {
    position: absolute;
    display: block;
    overflow: hidden;
    width: 45px;
    height: 30px;
    outline: 0;
    background: url(http://www.entfly.com/template/jiatian_jiaohu/images//portal/cssimg/btn.png) no-repeat;
}

.wk_btndiv a.aleft {
    right: 55px;
    background-position: 0 -30px;
}

.wk_btndiv a.agrayleft {
    background-position: 0 0;
    cursor: default;
}

.wk_btndiv a.aright {
    right: 0;
    background-position: -45px 0;
}

.wk_btndiv a.agrayright {
    background-position: -45px -30px;
    cursor: default;
}

.wk_scrollcontainer {
    position: relative;
    overflow: hidden;
    margin: 38px auto 0 auto;
    width: 1180px;
    height: 400px;
}

.wk_scrollcontainer ul {
    position: absolute;
    top: 0;
    left: 0;
    width: 1000%;
}

.wk_scrollcontainer li {
    float: left;
    overflow: hidden;
    margin-left: 0;
    width: 1180px;
}

.case .wk_case_list {
    position: relative;
    float: left;
    overflow: hidden;
    margin: 0 20px 20px 0;
    width: 275px;
    height: 190px;
    opacity: 0;
    -webkit-transition: all 1s;
    -moz-transition: all 1s;
    -o-transition: all 1s;
    transition: all 1s;
    -webkit-transform: translate3d(0, 100px, 0);
    transform: translate3d(0, 100px, 0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    -ms-transform: translate3d(0, 100px, 0);
}

.page3.active .case div.wk_case_list {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    -ms-transform: translate3d(0, 0, 0);
}

.page3.active .case div.wk_case_list:nth-child(2) {
    -webkit-transition-delay: 100ms;
    -moz-transition-delay: 100ms;
    transition-delay: 100ms;
}

.page3.active .case div.wk_case_list:nth-child(3) {
    -webkit-transition-delay: 200ms;
    -moz-transition-delay: 200ms;
    transition-delay: 200ms;
}

.page3.active .case div.wk_case_list:nth-child(4) {
    -webkit-transition-delay: 300ms;
    -moz-transition-delay: 300ms;
    transition-delay: 300ms;
}

.page3.active .case div.wk_case_list:nth-child(5) {
    -webkit-transition-delay: 400ms;
    -moz-transition-delay: 400ms;
    transition-delay: 400ms;
}

.page3.active .case div.wk_case_list:nth-child(6) {
    -webkit-transition-delay: 500ms;
    -moz-transition-delay: 500ms;
    transition-delay: 500ms;
}

.page3.active .case div.wk_case_list:nth-child(7) {
    -webkit-transition-delay: 600ms;
    -moz-transition-delay: 600ms;
    transition-delay: 600ms;
}

.page3.active .case div.wk_case_list:nth-child(8) {
    -webkit-transition-delay: 700ms;
    -moz-transition-delay: 700ms;
    transition-delay: 700ms;
}

.case .wk_case_list .wk_case_img {
    position: relative;
    width: 275px;
    height: 190px;
}

.case .wk_case_list .wk_case_img img {
    width: 275px;
    height: 190px;
}

.case .wk_case_list .wk_case_ico {
    position: absolute;
    top: 0;
    left: 0;
    width: 275px;
    height: 190px;
    background: url(http://www.entfly.com/template/jiatian_jiaohu/images//portal/cssimg/case_ico.png) no-repeat center;
    opacity: 0;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
    -webkit-opacity: 0;
    -moz-opacity: 0;
}

.case .wk_case_list:hover .wk_case_ico {
    opacity: 1;
    -webkit-opacity: 1;
    -moz-opacity: 1;
}

.case .wk_case_list .wk_case_xian {
    position: absolute;
    top: 22px;
    left: 22px;
    width: 241px;
    height: 146px;
}

.case .wk_case_list span {
    position: absolute;
    background: #0ddfba;
    -webkit-transition: all .3s cubic-bezier(.25, .46, .45, .94);
    -moz-transition: all .3s cubic-bezier(.25, .46, .45, .94);
    -o-transition: all .3s cubic-bezier(.25, .46, .45, .94);
    transition: all .3s cubic-bezier(.25, .46, .45, .94);
}

.case .wk_case_list .wk_span_1 {
    top: 0;
    left: 0;
    width: 0;
    height: 2px;
}

.case .wk_case_list .wk_span_2 {
    top: 0;
    right: 0;
    width: 2px;
    height: 0;
}

.case .wk_case_list .wk_span_3 {
    right: 0;
    bottom: 0;
    width: 0;
    height: 2px;
}

.case .wk_case_list .wk_span_4 {
    bottom: 0;
    left: 0;
    width: 2px;
    height: 0;
}

.case .wk_case_list:hover .wk_span_1,
.case .wk_case_list:hover .wk_span_3 {
    width: 100%;
}

.case .wk_case_list:hover .wk_span_2,
.case .wk_case_list:hover .wk_span_4 {
    height: 100%;
}

.page4 {
    background: url(http://www.entfly.com/template/jiatian_jiaohu/images//portal/cssimg/bg4.jpg) no-repeat center;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
}

.wk_about {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -185px;
    margin-left: -600px;
    width: 1200px;
}

.wk_about h2 {
    position: relative;
    top: 0;
    left: -400px;
    width: auto;
    -webkit-transition: all 1s ease 0s;
    transition: all 1s ease 0s;
    -ms-transition: all 1s ease 0s;
}

.page4.active .wk_about h2 {
    left: 0;
}

.wk_about h2.wk_h2 {
    display: none;
}

.wk_about_menu {
    position: absolute;
    top: 33px;
    right: -400px;
    -webkit-transition: all 1s ease 0s;
    transition: all 1s ease 0s;
    -ms-transition: all 1s ease 0s;
}

.wk_about_menu a {
    float: left;
    display: block;
    margin-left: 2px;
    width: 80px;
    height: 42px;
    background: #263551;
    color: #fff;
    text-align: center;
    font-size: 16px;
    line-height: 42px;
    cursor: pointer;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}

.wk_about_menu a.active {
    background: #334871;
}

.page4.active .wk_about_menu {
    right: 0;
}

.wk_about_div {
    position: relative;
    z-index: 1;
    overflow: hidden;
    margin: 400px auto 0 auto;
    width: 100%;
    -webkit-transition: all 1s ease 0s;
    transition: all 1s ease 0s;
    -ms-transition: all 1s ease 0s;
}

.page4.active .wk_about_div {
    margin: 70px auto 0 auto;
}

.wk_about_main {
    position: relative;
    margin: 0 auto;
}

.wk_about_left {
    position: relative;
    float: left;
    width: auto;
}

.wk_about_right {
    position: relative;
    float: right;
    width: 615px;
}

.wk_about_top {
    margin: 0 auto;
    padding-top: 20px;
}

.wk_about_top a {
    float: left;
    margin-right: 58px;
    width: 110px;
    height: 118px;
}

.wk_about_des {
    margin-top: 23px;
    color: #fff;
    font-size: 15px;
    line-height: 26px;
}

.wk_about_sec {
    margin-top: 15px;
}

.wk_news_list {
    position: relative;
    margin: 0 auto;
    padding-top: 15px;
}

.wk_news_list li {
    float: left;
    overflow: hidden;
    margin-left: 36px;
    width: 250px;
}

.wk_news_list li:first-child {
    margin-left: 0;
}

.wk_news_list li dl {
    margin: 0;
    padding: 5px 10px;
    width: 110px;
    height: 28px;
    border: 1px solid #fff;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    color: #fff;
    text-align: center;
}

.wk_news_list li dl dt {
    font-size: 16px;
    line-height: 28px;
}

.wk_news_list li h4 {
    margin-top: 7px;
    font-weight: 500;
    font-size: 18px;
    line-height: 62px;
    height: 62px;
    overflow: hidden;
}

.wk_news_list li h4 a {
    color: #fff;
}

.wk_news_list li .wk_news_des {
    overflow: hidden;
    height: 104px;
    color: #fff;
    font-size: 13px;
    line-height: 26px;
}

.wk_news_list li .wk_news_more {
    margin-top: 38px;
    height: 37px;
}

.wk_news_list li .wk_news_more a {
    display: block;
    width: 37px;
    height: 37px;
    background: url(http://www.entfly.com/template/jiatian_jiaohu/images//portal/cssimg/news_more.png) no-repeat;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    transition: all .3s;
}

.wk_news_list li .wk_news_more a:hover {
    background: url(http://www.entfly.com/template/jiatian_jiaohu/images//portal/cssimg/news_more.png) no-repeat 0 -37px;
}

.wk_history {
    padding-top: 25px;
    background: url(http://www.entfly.com/template/jiatian_jiaohu/images//portal/cssimg/time_x.png) no-repeat left 9px;
}

.wk_blk_18 {
    overflow: hidden;
    width: 1200px;
    zoom: 1;
}

.wk_blk_18 .wk_pcont {
    float: left;
    overflow: hidden;
    margin-left: 16px;
    width: 1098px;
}

.wk_blk_18 .ScrCont {
    margin-left: 0;
    width: 100000%;
    zoom: 1;
}

.wk_blk_18 #List1_1,
.wk_blk_18 #List2_1 {
    float: left;
}

.wk_blk_18 .LeftBotton {
    float: left;
    margin-top: 150px;
    margin-left: 0;
    width: 35px;
    height: 35px;
    background: url(http://www.entfly.com/template/jiatian_jiaohu/images//portal/cssimg/left_btn.png) no-repeat center;
}

.wk_blk_18 .RightBotton {
    float: right;
    margin-top: 150px;
    margin-right: 0;
    width: 35px;
    height: 35px;
    background: url(http://www.entfly.com/template/jiatian_jiaohu/images//portal/cssimg/right_btn.png) no-repeat center;
}

.wk_fzlc_b {
    float: left;
    margin: 0 33px;
    width: 300px;
    height: 294px;
}

.wk_fzlc_b h4 {
    margin: 0 auto;
    width: 300px;
    color: #fff;
    text-align: center;
    font-weight: 400;
    font-size: 18px;
    line-height: 54px;
}

.wk_his_ico {
    margin: 5px auto 0 auto;
    width: 300px;
    height: 11px;
    background: url(http://www.entfly.com/template/jiatian_jiaohu/images//portal/cssimg/history_ico.png) no-repeat center bottom;
}

.wk_fzlc_b dl {
    margin: 0 auto;
    padding: 15px 15px 0;
    width: 268px;
    height: 207px;
    border: 1px solid #fff;
    border-top: none;
}

.wk_fzlc_b dl dt,
.wk_fzlc_b dl dt img {
    margin: 0 auto;
    width: 268px;
    height: 115px;
}

.wk_fzlc_b dl dd {
    margin: 10px auto 0 auto;
    width: 268px;
    color: #fff;
    font-size: 14px;
    line-height: 24px;
}

.wk_fzlc_s {
    float: left;
    margin: 0 33px;
    width: 300px;
    height: 200px;
}

.wk_fzlc_s h4 {
    margin: 0 auto;
    width: 300px;
    color: #fff;
    text-align: center;
    font-weight: 400;
    font-size: 18px;
    line-height: 54px;
}

.wk_fzlc_s dl {
    margin: 0 auto;
    padding: 15px 15px 0;
    width: 268px;
    height: 114px;
    border: 1px solid #fff;
    border-top: none;
}

.wk_fzlc_s dl dt,
.wk_fzlc_s dl dt img {
    float: left;
    width: 100px;
    height: 100px;
}

.wk_fzlc_s dl dd {
    float: right;
    width: 155px;
    color: #fff;
    font-size: 14px;
    line-height: 24px;
}

@media(max-width: 1500px) {
    .wk_about {
        margin-top: -170px;
        margin-left: -540px;
        width: 1080px;
    }

    .wk_about h2 {
        display: none;
    }

    .wk_about h2.wk_h2 {
        position: relative;
        top: 0;
        left: -200px;
        display: block;
        width: auto;
        -webkit-transition: all 1s ease 0s;
        transition: all 1s ease 0s;
        -ms-transition: all 1s ease 0s;
    }

    .page4.active .wk_about h2.wk_h2 {
        left: 0;
    }

    .wk_about_menu {
        top: 0;
    }

    .page4.active .wk_about_div {
        margin: 35px auto 0 auto;
    }

    .wk_about_left img {
        width: 92%;
    }

    .wk_about_right {
        width: 575px;
    }

    .wk_about_top {
        padding-top: 5px;
    }

    .wk_about_top a {
        margin-right: 45px;
    }

    .wk_about_des {
        margin-top: 20px;
        font-size: 14px;
    }

    .wk_about_sec {
        margin-top: 10px;
    }

    .wk_news_list li {
        margin-left: 26px;
    }

    .wk_history {
        padding-top: 25px;
        background: url(http://www.entfly.com/template/jiatian_jiaohu/images//portal/cssimg/time_x1.png) no-repeat center 9px;
    }

    .wk_blk_18 {
        width: 1080px;
    }

    .wk_blk_18 .wk_pcont {
        margin-left: 16px;
        width: 978px;
    }

    .wk_fzlc_b {
        float: left;
        margin: 0 13px;
        width: 300px;
        height: 294px;
    }

    .wk_fzlc_s {
        float: left;
        margin: 0 13px;
        width: 300px;
        height: 200px;
    }
}

@media(max-width: 1150px) {
    .wk_about {
        margin-top: -170px;
        margin-left: -460px;
        width: 920px;
    }

    .wk_about h2 {
        display: none;
    }

    .wk_about h2.wk_h2 {
        position: relative;
        top: 0;
        left: -200px;
        display: block;
        width: auto;
        -webkit-transition: all 1s ease 0s;
        transition: all 1s ease 0s;
        -ms-transition: all 1s ease 0s;
    }

    .wk_about h2.wk_h2 img {
        width: 70%;
    }

    .page4.active .wk_about h2.wk_h2 {
        left: 0;
    }

    .wk_about_menu {
        top: 0;
    }

    .page4.active .wk_about_div {
        margin: 35px auto 0 auto;
    }

    .wk_about_left {
        width: 370px;
    }

    .wk_about_left img {
        width: 370px;
    }

    .wk_about_right {
        width: 515px;
    }

    .wk_about_top {
        padding-top: 5px;
    }

    .wk_about_top a {
        margin-right: 37px;
        width: 100px;
    }

    .wk_about_top a img {
        width: 100px;
    }

    .wk_about_des {
        margin-top: 10px;
        font-size: 12px;
    }

    .wk_about_sec {
        margin-top: 0;
    }

    .wk_news_list li {
        margin-left: 20px;
        width: 215px;
    }

    .wk_news_list li h4 {
        font-size: 16px;
    }

    .wk_news_list li .wk_news_des {
        font-size: 12px;
    }

    .wk_news_list li .wk_news_more {
        margin-top: 18px;
    }

    .wk_history {
        padding-top: 25px;
        background: url(http://www.entfly.com/template/jiatian_jiaohu/images//portal/cssimg/time_x2.png) no-repeat center 9px;
    }

    .wk_blk_18 {
        width: 920px;
    }

    .wk_blk_18 .wk_pcont {
        margin-left: 34px;
        width: 732px;
    }

    .wk_blk_18 .LeftBotton {
        margin-left: 25px;
    }

    .wk_blk_18 .RightBotton {
        margin-right: 25px;
    }

    .wk_fzlc_b {
        float: left;
        margin: 0 33px;
        width: 300px;
        height: 294px;
    }

    .wk_fzlc_s {
        float: left;
        margin: 0 33px;
        width: 300px;
        height: 200px;
    }
}

.page5 {
    background: url(http://www.entfly.com/template/jiatian_jiaohu/images//portal/cssimg/bg5.jpg) no-repeat center;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
}

.wk_service {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -230px;
    margin-left: -600px;
    width: 1200px;
}

.wk_service h2 {
    position: relative;
    top: 0;
    left: 50%;
    margin-left: -145px;
    width: auto;
    -webkit-transition: all 1s ease 0s;
    transition: all 1s ease 0s;
    -ms-transition: all 1s ease 0s;
}

.page5.active .wk_service h2 {
    left: 50%;
    margin-left: -245px;
}

.wk_service h2.wk_h2 {
    display: none;
}

.wk_service_xian {
    position: relative;
    margin-top: 28px;
    width: 0;
    height: 3px;
    background: #0ddfba;
    -webkit-transition: all 1s ease 0s;
    -moz-transition: all 1s ease 0s;
    transition: all 1s ease 0s;
    -ms-transition: all 1s ease 0s;
}

.page5.active .wk_service_xian {
    width: 100%;
}

.wk_service_main {
    position: relative;
    top: 200px;
    margin: 0 auto;
    padding: 22px 0;
    width: 1160px;
    background: url(http://www.entfly.com/template/jiatian_jiaohu/images//portal/cssimg/service_main_bj.png) repeat left top;
    opacity: 0;
    -webkit-transition: all 1s ease 0s;
    transition: all 1s ease 0s;
    -webkit-opacity: 0;
    -moz-opacity: 0;
    -ms-transition: all 1s ease 0s;
}

.page5.active .wk_service_main {
    top: 0;
    opacity: 1;
    -webkit-opacity: 1;
    -moz-opacity: 1;
}

.wk_service_main ul {
    overflow: hidden;
}

.wk_service_main ul li {
    position: relative;
    float: left;
    overflow: hidden;
    margin: 0 31px 22px;
    width: 170px;
    height: 100px;
}

.wk_service_main li span {
    position: absolute;
    background: #0ddfba;
    -webkit-transition: all .3s cubic-bezier(.25, .46, .45, .94);
    -moz-transition: all .3s cubic-bezier(.25, .46, .45, .94);
    -o-transition: all .3s cubic-bezier(.25, .46, .45, .94);
    transition: all .3s cubic-bezier(.25, .46, .45, .94);
}

.wk_service_main li .wk_span_1 {
    top: 0;
    left: 0;
    width: 0;
    height: 2px;
}

.wk_service_main li .wk_span_2 {
    top: 0;
    right: 0;
    width: 2px;
    height: 0;
}

.wk_service_main li .wk_span_3 {
    right: 0;
    bottom: 0;
    width: 0;
    height: 2px;
}

.wk_service_main li .wk_span_4 {
    bottom: 0;
    left: 0;
    width: 2px;
    height: 0;
}

.wk_service_main li:hover .wk_span_1,
.wk_service_main li:hover .wk_span_3 {
    width: 100%;
}

.wk_service_main li:hover .wk_span_2,
.wk_service_main li:hover .wk_span_4 {
    height: 100%;
}

@media(max-width: 1500px) {
    .wk_service {
        margin-top: -180px;
        margin-left: -540px;
        width: 1080px;
    }

    .wk_service h2 {
        display: none;
    }

    .wk_service h2.wk_h2 {
        position: relative;
        top: 0;
        left: 0;
        display: block;
        margin-left: -100px;
        width: auto;
        -webkit-transition: all 1s ease 0s;
        transition: all 1s ease 0s;
        -ms-transition: all 1s ease 0s;
    }

    .page5.active .wk_service h2.wk_h2 {
        left: 0;
        margin-left: 0;
    }

    .wk_service_xian {
        margin-top: 20px;
    }

    .wk_service_main {
        padding: 20px 0;
        width: 1060px;
    }

    .wk_service_main ul li {
        margin: 0 21px 10px;
    }
}

@media(max-width: 1150px) {
    .wk_service {
        margin-top: -190px;
        margin-left: -460px;
        width: 920px;
    }

    .wk_service h2 {
        display: none;
    }

    .wk_service h2.wk_h2 {
        position: relative;
        top: 0;
        left: 0;
        display: block;
        margin-left: -100px;
        width: auto;
        -webkit-transition: all 1s ease 0s;
        transition: all 1s ease 0s;
        -ms-transition: all 1s ease 0s;
    }

    .wk_service h2.wk_h2 img {
        width: 70%;
    }

    .page5.active .wk_service h2.wk_h2 {
        left: 0;
        margin-left: 0;
    }

    .wk_service_xian {
        margin-top: 20px;
    }

    .wk_service_main {
        padding: 20px 0;
        width: 900px;
    }

    .wk_service_main ul li {
        margin: 0 5px 10px;
    }
}

.page6 {
    background: url(http://www.entfly.com/template/jiatian_jiaohu/images//portal/cssimg/bg6.jpg) no-repeat center;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
}

.wk_contact {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -210px;
    margin-left: -600px;
    width: 1200px;
}

.wk_contact_left {
    position: absolute;
    top: 0;
    left: -100px;
    -webkit-transition: all 1s ease 0s;
    -moz-transition: all 1s ease 0s;
    transition: all 1s ease 0s;
    -ms-transition: all 1s ease 0s;
}

.page6.active .wk_contact_left {
    left: 85px;
}

.wk_contact.active .wk_contact_left {
    left: 85px;
}

.wk_contact_left1 {
    display: none;
}

.wk_contact_right {
    position: absolute;
    top: 30px;
    right: -100px;
    width: 540px;
    -webkit-transition: all 1s ease 0s;
    transition: all 1s ease 0s;
    -ms-transition: all 1s ease 0s;
}

.wk_cont_div {
    margin: 0 auto;
    padding-top: 8px;
    width: 540px;
}

.wk_cont_div li {
    float: left;
    margin-top: 24px;
    height: 44px;
    color: #ccc;
    font-size: 18px;
    line-height: 44px;
}

.wk_cont_div li a {
    color: #ccc;
}

.wk_cont_div span {
    float: left;
    display: block;
    margin-right: 21px;
    width: 44px;
    height: 44px;
    cursor: pointer;
    -webkit-transition: all .2s;
    transition: all .2s;
}

.wk_cont_div li span.wk_cont_b {
    background: url(http://www.entfly.com/template/jiatian_jiaohu/images//portal/cssimg/cont_b.png) no-repeat;
}

.wk_cont_div li:hover span.wk_cont_b {
    background: url(http://www.entfly.com/template/jiatian_jiaohu/images//portal/cssimg/cont_b.png) no-repeat 0 -44px;
}

.wk_cont_div li span.wk_cont_c {
    background: url(http://www.entfly.com/template/jiatian_jiaohu/images//portal/cssimg/cont_c.png) no-repeat;
}

.wk_cont_div li:hover span.wk_cont_c {
    background: url(http://www.entfly.com/template/jiatian_jiaohu/images//portal/cssimg/cont_c.png) no-repeat 0 -44px;
}

.wk_cont_div li span.wk_cont_d {
    background: url(http://www.entfly.com/template/jiatian_jiaohu/images//portal/cssimg/cont_d.png) no-repeat;
}

.wk_cont_div li:hover span.wk_cont_d {
    background: url(http://www.entfly.com/template/jiatian_jiaohu/images//portal/cssimg/cont_d.png) no-repeat 0 -44px;
}

li {
    list-style-type: none
}

.wk_serve-ico-kf:hover .wk_cont_a {
    display: block;
    color: #ed5736;
    -webkit-animation: gWelHealth .8s ease-in-out;
    -moz-animation: gWelHealth .8s ease-in-out;
    -o-animation: gWelHealth .8s ease-in-out;
    -ms-animation: gWelHealth .8s ease-in-out;
    animation: gWelHealth .8s ease-in-out;
    list-style-type: none
}

.page6.active .wk_contact_right {
    right: 55px;
}

.wk_contact.active .wk_contact_right {
    right: 55px;
}

@media(max-width: 1500px) {
    .wk_contact {
        margin-top: -155px;
        margin-left: -540px;
        width: 1080px;
    }

    .wk_contact_left {
        display: none;
    }

    .wk_contact_left1 {
        position: absolute;
        top: 0;
        left: -50px;
        display: block;
        -webkit-transition: all 1s ease 0s;
        -moz-transition: all 1s ease 0s;
        transition: all 1s ease 0s;
        -ms-transition: all 1s ease 0s;
    }

    .page6.active .wk_contact_left1 {
        left: 55px;
    }

    .wk_contact.active .wk_contact_left1 {
        left: 55px;
    }

    .wk_contact_right {
        top: 20px;
    }

    .page6.active .wk_contact_right {
        right: 25px;
    }

    .wk_contact.active .wk_contact_right {
        right: 25px;
    }

    .wk_cont_div {
        padding-top: 18px;
    }

    .wk_cont_div li {
        margin-top: 15px;
    }
}

@media(max-width: 1150px) {
    .wk_contact {
        margin-top: -155px;
        margin-left: -460px;
        width: 920px;
    }

    .wk_contact_left {
        display: none;
    }

    .wk_contact_left1 {
        position: absolute;
        top: 0;
        left: -50px;
        display: block;
        -webkit-transition: all 1s ease 0s;
        -moz-transition: all 1s ease 0s;
        transition: all 1s ease 0s;
        -ms-transition: all 1s ease 0s;
    }

    .page6.active .wk_contact_left1 {
        left: 0;
    }

    .wk_contact.active .wk_contact_left1 {
        left: 0;
    }

    .wk_contact_right {
        top: 20px;
        width: 500px;
    }

    .page6.active .wk_contact_right {
        right: 0;
    }

    .wk_contact.active .wk_contact_right {
        right: 0;
    }

    .wk_cont_div {
        padding-top: 18px;
    }

    .wk_cont_div li {
        margin-top: 15px;
    }
}

.fp-auto-height .wk_footer_side {
    padding: 26px 0;
    background: #0e0e0e;
    opacity: 0;
    -webkit-transition: all 1s ease 0s;
    -moz-transition: all 1s ease 0s;
    transition: all 1s ease 0s;
    -webkit-opacity: 0;
    -moz-opacity: 0;
    -ms-transition: all 1s ease 0s;
}

.fp-auto-height.active .wk_footer_side {
    padding: 26px 0;
    background: #0e0e0e;
    opacity: 1;
    -webkit-opacity: 1;
    -moz-opacity: 1;
}

.wk_footer {
    min-height: 52px;
    margin: 0 auto;
    width: 1200px;
    background: url(http://www.entfly.com/template/jiatian_jiaohu/images//footer_logo.png) no-repeat right center;
    color: #b6b3b3;
    line-height: 26px;
}

.wk_footer a {
    color: #b6b3b3;
}

@media(max-width: 1500px) {
    .wk_footer_side {
        padding: 10px 0;
        width: 100%;
        min-width: 1080px;
    }

    .wk_footer {
        width: 1160px;
    }
}

@media(max-width: 1150px) {
    .wk_footer_side {
        padding: 10px 0;
        width: 100%;
        min-width: 920px;
    }

    .wk_footer {
        width: 1000px;
    }
}
</style>
