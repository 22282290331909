import VueRouter from "vue-router";
import Vue from "vue";
// 引入组件
import Index from '../views/Index'
import productsIndex from '../views/products/Index'
import productspowertms from '../views/products/powertms'
import productsweisu from '../views/products/weisu'
import productsbrand from "../views/products/brand"
import productswkb from "../views/products/wkb"
import productswkbsIndex from "../views/products/wkbs/Index"

//解决方案
import solutionsIndex from '../views/solutions/Index'
import solutionsSy from '../views/solutions/sy'
import solutionsKj from '../views/solutions/kj'
import solutionsXmt from '../views/solutions/xmt'
import productssolutionssIndex from "../views/products/solutionss/Index"

// 新闻动态
import newsUpdates from '../views/newsUpdates/Index'
import newsUpdatesgsxw from '../views/newsUpdates/gsxw'
import newsUpdatesHyxw from '../views/newsUpdates/hyxw'
import newsUpdatesMtbt from '../views/newsUpdates/mtbt'
import newsUpdatesDetail from '../views/newsUpdates/Detail'

// 关注助力
import abloutCompany from '../views/abloutCompany/Index'

// 注册
Vue.use(VueRouter)

// 声明路由路径
const router = new VueRouter({
    routes: [
        { path: '/', component: Index },
        { path: '/productsIndex', component: productsIndex },
        { path: '/productspowertms', component: productspowertms },
        { path: '/productsweisu', component: productsweisu },
        { path: '/productsbrand', component: productsbrand },
        { path: '/productswkb', component: productswkb },
        { path: '/productswkb/wkbs/Index', component: productswkbsIndex },

        { path: '/solutionsIndex', component: solutionsIndex },
        { path: '/solutionsSy', component: solutionsSy },
        { path: '/solutionsKj', component: solutionsKj },
        { path: '/solutionsXmt', component: solutionsXmt },
        { path: '/products/solutionss/Index', component: productssolutionssIndex },

        {
            path: '/newsUpdatesIndex', component: newsUpdates,
            redirect: "/newsUpdatesIndex/Gsxw",
            children: [
                {
                    path: '/newsUpdatesIndex/Gsxw',
                    component: newsUpdatesgsxw
                },
                {
                    path: '/newsUpdatesIndex/Hyxw',
                    component: newsUpdatesHyxw
                },
                {
                    path: '/newsUpdatesIndex/Mtbt',
                    component: newsUpdatesMtbt
                },
                {
                    path: '/newsUpdatesIndex/Detail',
                    component: newsUpdatesDetail
                }
            ]
        },

        {path:'/abloutCompany',component:abloutCompany}
    ]
})

export default router