<template>
    <div class="gsxw_body">
        <div class="wk_c_right_name displayCenter">
            <el-image style="width: 19px; height: 19px" src="http://www.entfly.com/template/jiatian_jiaohu/images//portal/right_wz.png"></el-image>
            <span class="plr10">您现在的位置：</span>
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item>首页</el-breadcrumb-item>
                <el-breadcrumb-item>新闻动态</el-breadcrumb-item>
                <el-breadcrumb-item>行业新闻</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="gsxw_list">
            <div class="gsxw_main displayCenterCenter mtb20 pb20" v-for="(items,index) in newsUpdatesData" @click="onRouterClick">
                <el-image style="width: 191px; height: 123px;cursor: pointer;" :src="items.imgUrl"></el-image>
                <div class="gsxw_main-right">
                    <h5>{{ items.name }}</h5>
                    <span>{{ items.more }}</span>
                    <p class="ptb10">{{ items.message }}</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import data from '../../public/static/newsUpdates.json'
export default {
    created() {
        this.newsUpdatesData = data.data.Hyxw
    },
    methods:{
        onRouterClick(){
            this.$router.push({ path: '/newsUpdatesIndex/Detail', query: { name: 'hyxw' } })
        }
    }
}
</script>
<style scoped>
.wk_c_right_name {
    height: 55px;
    position: relative;
    border-bottom: 1px solid #E6E6E6;
    color: #999;
    font-size: 12px !important;
}
.gsxw_main{
    height: 179px;
    position: relative;
    border-bottom: 1px dashed #E6E6E6;
}
.gsxw_main-right{
    width: calc(100% - 257px);
    margin-left: 20px;
    cursor: pointer;
}
.gsxw_main-right h5{
    width: 595px;
    color: #DE262E;
    height: 44px;
    line-height: 44px;
    font-size: 18px;
    /* border-bottom: 1px dashed #dedede; */
    white-space: nowrap;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    overflow: hidden
}
.gsxw_main-right:hover h5{
    color: #666;
}
.gsxw_main-right p{
    width: 595px;
    line-height: 24px;
    height: 85px;
    display: block;
    overflow: hidden;
    font-size: 12px;
    color: #666666;
}
.gsxw_main-right span{
    color: #666;
    font-size: 12px;
    line-height: 20px;
    width: 595px;
    cursor: pointer;
}
</style>
<style>

.wk_c_right_name .el-breadcrumb{
    font-size: 12px;
}
</style>