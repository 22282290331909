import Vue from "vue";
import Vuex from "vuex";


Vue.use(Vuex);

//要设置的全局访问的state对象
var state = {
    RouterLink: null, //
};

//全局使用 this.$store.getters.xx （xx为getters里的值） 可获取当前最新的存储值
var getters = {
    //实时监听state值的变化
    newsUpateRouterLink(state) {
        return state.RouterLink;
    },

};

// mutations为同步方法, 全局使用 this.$store.commit.xx(xx为mutations里的值,传值) 可传值修改state对应的值
var mutations = {
    set_RouterLink(state, RouterLink) {
        console.log(RouterLink,'RouterLink')
        state.RouterLink = RouterLink;
    },
};

// actions为异步方法, 全局使用 this.$store.dispatch(xx,传值)  xx为actions里的值
var actions = {
    change_RouterLink(context, obj) {
        console.log(obj,'set_RouterLink')
        context.commit("set_RouterLink", obj);
    },

};

var store = new Vuex.Store({ state, getters, mutations, actions });

export default store;
