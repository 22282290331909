<template>
    <div class="container">
        <div class="container_body">
            <header-component :status="true"></header-component>
            <div class="products_Banner">
                <img :src="BannerUrl" alt="" srcset="">
            </div>
            <div class="content w_100 box_size">
                <div class="tabsList_body ct displayBetween">
                    <div class="menu_left">
                        <div class="menu_left_title">
                            新闻资讯<span>News</span>
                        </div>
                        <el-menu :default-active="RouterLink" router class="el-menu-vertical-demo" @select="handleSelect">
                            <el-menu-item index="/newsUpdatesIndex/Gsxw">
                                <span slot="title">公司新闻</span>
                            </el-menu-item>
                            <el-menu-item index="/newsUpdatesIndex/Hyxw">
                                <span slot="title">行业新闻</span>
                            </el-menu-item>
                            <el-menu-item index="/newsUpdatesIndex/Mtbt">
                                <span slot="title">媒体报道</span>
                            </el-menu-item>
                        </el-menu>
                        <div class="menu_left_title mt20">
                            联系方式<span>Contact</span>
                        </div>
                        <div class="menu_left_infomations box_size displaycolumn p2_10">
                            <span v-for="(items, index) in infomations" :key="index">
                                <strong>{{ items.name }}</strong>
                                <template v-if="items.name == '邮 箱：'">
                                    <a href="http://mailto:vip@entfly.com" target="_blank" rel="noopener noreferrer">{{
                                        items.content }}</a>
                                </template>
                                <template v-else>{{ items.content }}</template>
                            </span>
                        </div>
                    </div>
                    <div class="menu_content">
                        <router-view></router-view>
                    </div>
                </div>
            </div>
            <footerComponent></footerComponent>
        </div>
    </div>
</template>
<script>
import headerComponent from '../../components/comFrom/Header.vue'
import footerComponent from '../../components/comFrom/footer.vue'
export default {
    name: 'products',
    data() {
        return {
            BannerUrl: 'http://www.entfly.com/template/jiatian_jiaohu/images/bn6.jpg',
            activeName: '/newsUpdates',
            RouterLink: '/newsUpdatesIndex/Gsxw',
            infomations: [
                {
                    name: '地 址：',
                    content: '北京海淀区丰智东路13号A2栋',
                },
                {
                    name: '邮 编：',
                    content: '100085',
                },
                {
                    name: '咨询热线：',
                    content: '010-60608145',
                },
                {
                    name: '服务投拆：',
                    content: '4008089569',
                },
                {
                    name: '邮 箱：',
                    content: 'vip@entfly.com',
                }
            ]
        }
    },
    components: {
        headerComponent,
        footerComponent
    },
    created() {
        this.routerLink(this.$router.app._route.path)
    },
    mounted() {
        
        console.log('44444444444',this.$store.getters.RouterLink)
    },
    methods: {
        handleSelect(key, keyPath) {
            this.RouterLink = keyPath
        },
        routerLink(path) {
            if (path == '/newsUpdatesIndex/Gsxw') {
                this.RouterLink = '/newsUpdatesIndex/Gsxw';
            } else if (path == '/newsUpdatesIndex/Hyxw') {
                this.RouterLink = '/newsUpdatesIndex/Hyxw';
            } else if (path == '/newsUpdatesIndex/Mtbt') {
                this.RouterLink = '/newsUpdatesIndex/Mtbt';
            }
        },
    }
}
</script>
<style scoped>
.container {
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    flex-direction: column;
    overflow: hidden;
}

.container_body {
    width: 100%;
    height: auto;
    overflow-y: scroll;
}

.products_Banner {
    width: 100%;
    min-width: 1180px;
    height: 300px;
    overflow: hidden;
    margin-top: 60px;
}

.products_Banner img {
    width: 100%;
    height: 100%;
}


.content_tabs {
    width: 100%;
    height: 65px;
    background: url(http://www.entfly.com/template/jiatian_jiaohu/images//about_line.jpg) repeat-x left bottom;
    overflow: hidden;
}

.contents-el-tabs {
    width: 500px;
    height: 65px;
    line-height: 65px;
    margin: 0 auto;
    font-size: 16px;
}

.tabsList_body {
    width: 1190px;
    margin: 0 auto;
}

.tabsList {
    width: 100%;
}

.tabsList_body .tabsList .items {
    background-size: cover;
    width: 366px;
    height: 380px;
    background: #f3f0ed;
    margin: 15px;
    color: #666;
}

.BJ_images {
    width: 100%;

}

.tabsList .items .BJ_images:hover {
    box-shadow: 0px 0px 7px #BBB;
}

.tabsList .items .BJ_images img {
    width: 100%;
    height: 200px;
}

.menu_left {
    width: 245px;
}

.menu_left_title {
    display: flex;
    align-items: center;
    height: 40px;
    line-height: 35px;
    background: #DE262E;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: bold;
    padding-left: 15px;
}

.menu_left_title span {
    margin-left: 10px;
    font-size: 11px;
    height: 25px;
    font-weight: normal;
    color: #DDD;
}

.menu_left_infomations {
    width: 100%;
    height: auto;
    padding-top: 75px;
    padding-bottom: 20px;
    overflow: hidden;
    margin-bottom: 20px;
    min-height: 100px;
    border: 1px solid #EEE;
    border-top: 0;
    background: #F6F6F6 url(http://www.entfly.com/template/jiatian_jiaohu/images//contact.jpg) left top no-repeat
}

.menu_left_infomations span {
    font-size: 14px;
    line-height: 24px;
    color: #666;
}

.ct {
    min-height: 300px;
    padding-bottom: 75px;
    background: url(http://www.entfly.com/template/jiatian_jiaohu/images//portal_ft.jpg) center bottom no-repeat
}

.menu_content {
    width: 880px;
}
</style>
<style>
.el-tabs__nav-wrap::after {
    background: transparent !important;
}

.el-tabs__item {
    font-size: 16px !important;
}

.el-tabs__item.is-active {
    color: #DE262E !important;
}

.el-tabs__active-bar {
    background-color: #DE262E !important;
}

.el-tabs__item:hover {
    color: #DE262E !important;
}

.el-menu {
    background: #FFF8FB !important;
}

.el-menu-item.is-active {
    color: #DE262E !important;
    background: url(http://www.entfly.com/template/jiatian_jiaohu/images//left_sel_03.gif) left center no-repeat;
}

.el-menu-item {
    font-size: 16px !important;
    border: 1px solid #EEE;
}
</style>