<template>
    <div id="week_nav">
        <div class="wk_navwp">
            <div class="wk_lonav">
                <div class="wk_logo">
                    <h2><a href="http://www.entfly.com/" rel="noopener noreferrer"><img
                                src="http://www.entfly.com/template/jiatian_jiaohu/images//logo.png" alt=""></a></h2>
                </div>
                <div class="displayFlexCenter">
                <div class="wk_inav">
                    <ul class="nav">
                        <li> <span class="el-dropdown-link size16">
                                <router-link :to="{path:'/'}">首页</router-link>
                            </span></li>
                        <li><el-dropdown>
                                <span class="el-dropdown-link" >
                                    <router-link :to="{path:'/productsIndex', query: {name:'products'}}" :class="CurrentName=='products'?'active':''">产品中心</router-link>
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item> <a href="http://kj.entfly.com/" target="_blank">品牌控价</a></el-dropdown-item>
                                    <el-dropdown-item><a href="http://www.iweisu.net" target="_blank">微溯云</a></el-dropdown-item>
                                    <el-dropdown-item ><div @click="routerLink('/productspowertms','products')">防伪溯源系统</div></el-dropdown-item>
                                    <el-dropdown-item ><div @click="routerLink('/productswkb','products')">微控宝</div></el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown></li>
                        <li> <el-dropdown>
                                <span class="el-dropdown-link">
                                    <router-link :to="{path:'/solutionsIndex', query: {name:'solutions'}}" :class="CurrentName=='solutions'?'active':''">解决方案</router-link>
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item><div @click="routerLink('/solutionsXmt','solutions')">新媒体营销</div></el-dropdown-item>
                                    <el-dropdown-item><a href="http://kj.entfly.com" target="_blank">品牌控价</a></el-dropdown-item>
                                    <el-dropdown-item><div @click="routerLink('/solutionsSy','solutions')">产品溯源</div></el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown></li>
                        <li><el-dropdown>
                                <span class="el-dropdown-link">
                                    <router-link :to="{path:'/newsUpdatesIndex', query: {name:'newsUpdates'}}" :class="CurrentName=='newsUpdates'?'active':''">新闻动态</router-link>
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item><div  @click="routerLink('/newsUpdatesIndex/Gsxw','newsUpdates')">公司新闻</div></el-dropdown-item>
                                    <el-dropdown-item><div  @click="routerLink('/newsUpdatesIndex/Hyxw','newsUpdates')">行业新闻</div></el-dropdown-item>
                                    <el-dropdown-item><div  @click="routerLink('/newsUpdatesIndex/Mtbt','newsUpdates')">媒体报道</div></el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown></li>
                        <li><el-dropdown>
                                <span class="el-dropdown-link">
                                    <router-link :to="{path:'/abloutCompany', query: {name:'abloutCompany',index:0}}" :class="CurrentName=='abloutCompany'?'active':''">关于助力</router-link>
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item ><div @click="routerLinks('/abloutCompany','0')">公司简介</div></el-dropdown-item>
                                    <el-dropdown-item ><div @click="routerLinks('/abloutCompany','1')">企业文化</div></el-dropdown-item>
                                    <el-dropdown-item ><div @click="routerLinks('/abloutCompany','2')">发展历程</div></el-dropdown-item>
                                    <el-dropdown-item ><div @click="routerLinks('/abloutCompany','3')">招纳贤才</div></el-dropdown-item>
                                    <el-dropdown-item ><div @click="routerLinks('/abloutCompany','4')">联系我们</div></el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown></li>
                    </ul>

                </div>
                <el-dropdown :hide-on-click="false">
                    <span class="el-dropdown-link displayFlexCenter ml20">
                        <el-avatar :size="25"
                            src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"></el-avatar>
                        <i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item><div  @click="routerLinkss()">立即登录</div></el-dropdown-item>
                        <el-dropdown-item><div  @click="routerLinkss()">QQ登录</div></el-dropdown-item>
                        <el-dropdown-item><div  @click="routerLinkss()">微信登录</div></el-dropdown-item>
                        <el-dropdown-item><div  @click="routerLinkss()">马上注册</div></el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
            </div>
        </div>
        <OnlineConsultation/>
    </div>
</template>
<script>
import OnlineConsultation from './OnlineConsultation.vue'
 export default{
    data(){
        return{
            CurrentName:null,
        }
    },
    components:{
        OnlineConsultation
    },
    mounted(){
        this.CurrentName = this.$route.query.name
        console.log(this.$route.query.name,'query')
    },
    methods:{
        routerLink(path,names) {
            // this.$parent.routerLink(path)
            this.CurrentName = names;
            this.$router.push({ path:path, query: { name: this.CurrentName } })
        },
        routerLinks(path,indexs) {
            console.log(path,indexs,'ddd')
            if(this.CurrentName=='abloutCompany'){
                this.$parent.routerLink(indexs)
            }else{
                console.log(333)
                this.CurrentName = 'abloutCompany';
                this.$router.push({ path:path, query: { name: this.CurrentName,index:indexs } })
            }
        },
        routerLinkss(){
            alert('暂无开放')
        }
    }
 }
</script>
<style scoped>
#week_nav {
    width: 100%;
    min-width: 1180px;
    box-sizing: border-box;
    height: 60px;
    background: #FFF;
    margin: 0 auto;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
}

#week_nav .wk_navwp {
    width: 1180px;
    padding: 0;
    margin: 0 auto;
}

.wk_lonav {
    overflow: hidden;
    width: 1180px;
    height: 60px;
    display: flex;
    justify-content: space-between
}

.wk_logo {
    width: 230px;
    height: 45px;
    margin-top: 9px;
    overflow: hidden;
}

.nav {
    display: flex;
    width: 500px;
    justify-content: space-between;
}

.nav>li {
    width: 100%;
    height: 60px;
    text-align: center;
    line-height: 60px;
    background: url(http://www.entfly.com/template/jiatian_jiaohu/images//ico2.png) no-repeat left center;
    position: relative;
    list-style-type: none;
}

.nav>li:first-child {
    background: transparent;
}
.active{
    color:#DE262E
}


</style>
<style>
.el-dropdown{
    font-size:16px !important
}
.el-dropdown-menu__item:not(.is-disabled):hover {
    background: red !important;
    color: #fff !important;
  }
  .el-dropdown-menu__item:active{
    background: red !important;
    color: #fff !important;
  }
</style>