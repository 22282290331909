<template>
    <div class="container">
        <header-component :status="true"></header-component>
        <div class="container_body mt60">
            <div class="wk_news_box">
                <div class="wk_portalhead_bg">
                    <ul>
                        <li v-for="(items, index) in tabsData.list" @click="onRouterClick(items.path)"
                            :class="items.name1 == CurrentName ? 'active' : ''">{{ items.name }}</li>
                    </ul>
                </div>
                <h1>{{ tabsUser.title }}</h1>
                <p class="xg1">{{ tabsUser.date }}<span class="pipe">|</span>发布者: {{tabsUser.admin}}<span class="pipe">|</span>查看:<em id="_viewnum">{{tabsUser.num}}</em><span class="pipe">|</span>评论: {{tabsUser.Count}}</p>
            </div>
            <div class="wk_ymbg">
                <div class="ct2 cl displaycolumnCenter" id="ct">
                    <template v-if="CurrentName == 'wkb'">
                        <img class="mt40 mb50"
                            src="http://www.entfly.com/data/attachment/portal/201702/09/174114rsj9hlfpj99e3lyl.jpg" alt=""
                            srcset="">
                    </template>
                    <template v-if="CurrentName == 'weisu'">
                        <div class="article_content mtb20">
                            <div class="WordSection1" v-for="(items, index) in weisuData" :key="index">
                                <h2>{{ items.title }}</h2>
                                <div class="w_100" v-for="(itemss, indexs) in items.data" :key="indexs">
                                    <h3 class="mb20 ml20">{{ itemss.title }}</h3>
                                    <img class="w_100" v-if="itemss.imgUrl" :src="itemss.imgUrl" alt="" srcset="">
                                    <div v-for="(itemsss, indexss) in itemss.data" :key="indexss" style="text-indent: 2em;"
                                        class="mtb20">{{ itemsss.name }}
                                        <h4>{{ itemsss.title }}</h4>
                                        <div v-for="(itemssss, indexsss) in itemsss.data" :key="indexsss"
                                            style="text-indent: 2em;">
                                            <h4>{{ itemssss.title }}</h4>
                                            <div>{{ itemssss.name }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-if="CurrentName == 'powertms'">
                        <div class="swiper" ref="swiper">
                            <div class="swiper-wrapper">
                                <div class="swiper-slide" v-for="(items, index) in swiperData" :key="index"><img
                                        :src="items.url" :alt="items.name" srcset=""></div>
                                <div class="swiper-slide">
                                    <div class="article_content mtb20">
                            <div class="WordSection1" v-for="(items, index) in weisuData" :key="index">
                                <h2>{{ items.title }}</h2>
                                <div class="w_100" v-for="(itemss, indexs) in items.data" :key="indexs">
                                    <h3 class="mb20 ml20">{{ itemss.title }}</h3>
                                    <img class="w_100" v-if="itemss.imgUrl" :src="itemss.imgUrl" alt="" srcset="">
                                    <div v-for="(itemsss, indexss) in itemss.data" :key="indexss" style="text-indent: 2em;"
                                        class="mtb20">{{ itemsss.name }}
                                        <h4>{{ itemsss.title }}</h4>
                                        <div v-for="(itemssss, indexsss) in itemsss.data" :key="indexsss"
                                            style="text-indent: 2em;">
                                            <h4>{{ itemssss.title }}</h4>
                                            <div>{{ itemssss.name }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                                </div>
                            </div>
                            <div class="swiper-button-prev"></div>
                            <div class="swiper-button-next"></div>
                        </div>
                    </template>
                </div>
            </div>
            <footerComponent></footerComponent>
        </div>
    </div>
</template>
<script>
import headerComponent from '../../../components/comFrom/Header.vue'
import footerComponent from '../../../components/comFrom/footer.vue'
// 引入js
import Swiper from "swiper";
// 引入css
import "swiper/css/swiper.min.css";
export default {
    name: 'products',
    data() {
        return {
            BannerUrl: 'http://www.entfly.com/template/jiatian_jiaohu/images/bn6.jpg',
            tabsData: {
                list: [{ name: '全部', path: '/productsIndex', name1: 'products' },
                { name: '防伪溯源系统', path: '/productspowertms', name1: 'powertms' },
                { name: '微溯云', path: '/productsweisu', name1: 'weisu' },
                { name: '品牌助手', path: '/productsbrand', name1: 'brand' },
                { name: '微控宝', path: '/productswkb', name1: 'wkb' }], 
               
                
            },
            tabsUser:{
                title: '', 
                date: '',
                admin:'',
                Count:0,
                num:0
            },
            weisuData: [
                {
                    title: '1、行业背景',
                    data: [
                        {
                            title: '',
                            data: [
                                { name: '以移动互联网、云计算、大数据、物联网等为核心的第四次工业革命已经到来，新的思想新的技术新的组织方式将对整个行业带来深刻的改造，较之前两次工业革命而言，第四次工业革命来势更加迅猛也更加彻底，逼迫企业或主动或被动为了生存必须参与其中，而如果想要企业能有更好的发展，在跟随大潮流之上还需不断创新走在前列争取成为引领者，而就当前来看有如下几个显著的趋势：' },
                                { name: '电子商务渠道快速发展，同时也需尽量避免蚕食实体渠道份额；移动互联网数字和全渠道营销，实现线上线下最大增长潜力；县级市及农村通路贡献率比重超过城市，成为新的增长引擎；产品和渠道选择更加多元，对体验和服务要求持续提升；高端化、定制化趋势明显，生产力技术发展逐渐激活长尾市场；随机选择转向品牌认同，安全、质量新鲜程度和参与度要求提高；扁平化去中间层趋势显现，企业对产品全生命周期管控需求明显。' },
                                { name: '随着中国经济改革的不断发展，国内外销售市场也在不断扩大，更大的机会也伴随着更多的竞争与挑战；在企业市场销售提升的同时，销售行为不规范、窜货、假货仿冒品等问题日益凸显，同行业同品类产品间竞争越发激烈，企业生产、销售模式等都需要不断改革创新，才能赢得现在与未来；在信息化和新智能工业革命的大背景下，不论是新兴行业还是传统行业都需要引入信息化管理，有效管理销售市场，并加以必要的约束，通过线上商城、积分兑换、营销活动等促销方式提高消费者忠诚度，挖掘潜在新消费者，提升销售份额。' },
                                { name: '无论是国际上还是在国内，食品安全问题日益突出，食源性疾病危害越来越大。实施食品可追溯系统的重要性日益凸现。虽然等多种有效的控制食品安全的管理办法纷纷被引入并在实践中运用，均取得了一定的效果。但是上述的管理办法都主要是对加工环节进行控制，缺少将整个供应链连接起来的手段。追溯系统强调产品的惟一标识和全过程追踪，对实施可追溯系统的产品，在其各个生产环节，可以实行等质量控制方法对整个供应链各个环节的产品信息进行跟踪与追溯，一旦发生食品安全问题，可以有效地追踪到食品的源头，及时召回不合格产品，将损失降到最低。' },
                                { name: '随着人们食品安全意识的觉醒，人们对食品安全的要求也越来越高，向消费者提供真实可靠的食品信息，将成为消费者选购产品必要的决策依据，同时也是提高企业形象的重要手段之一。响应国家加强食品监管的号召，遵循可追溯性是食品安全保障的基本原则，通过食品溯源实现产品的原料采集、生产加工、包装运输以及批发零售的环节链条进行全程有效监管。' }

                            ]
                        }
                    ]
                }, {
                    title: '2、系统必要性',
                    data: [
                        {
                            title: '1.市场发展趋势',
                            data: [
                                { name: '随着生活水平的逐步提高，民众对待产品的质量需要日趋苛刻，民众从单一的解决温饱问题转变对精神享受的依赖；对待品质的理解从接受到满足需求；产品质量是衡量一个国家经济发展水平和文明程度高低的重要指标之一。但近些年来，产品质量问题层出不穷，严重侵害了消费者的合法权益。国外许多学者对产品质量监管问题进行了多方面的研究，已形成了较为完善的产品质量监管理论体系，这些理论充分揭示了产品质量监管的必要性和可行性。然而，我国现行产品质量监管法律制度却存在诸多缺憾或问题。法律法规缺乏协调性、责任制度设计不明确、缺陷产品召回制度缺失，民众对待瑕疵品诉求无门。' },
                                { name: '从食品安全到乘车旅行、从娱乐消费到居家生活。质量问题一直伴随着民众左右，从三鹿奶粉事件到上海楼脆脆事件再到上海喜福事件再到温州动车事件。一件件触目惊心的事件背后暴露质量问题的监管缺失或匮乏，从民众到中央高层已经深刻认识并清晰的知道了质量监管的重要性。民众对待品质的需要越来越凸显出品质监管的重要性。截止到年月，全国共有省、自治区、直辖市及较大的市人大或人民政府制定了产品质量地方性法规部，地方性政府规章部，但是在执行过程中仍存在较大的偏差及漏洞，法律的健全、责任的追究、监管的需求亟待完善。' },

                            ]
                        },
                        {
                            title: '2.国家政策支持',
                            data: [
                                { name: '2004年，国务院发布《关于进一步加强食品安全工作的决定》，提出建立农产品质量安全追溯体系。自此以后，“追溯体系”、“条形码”、“二维码”等，逐渐走进了寻常百姓的生活。不论是在线上或线下，生鲜果蔬、粮油、茶叶、牛奶、畜禽产品及水产品等领域，已有不少追溯系统的身影。' },
                                { name: '《关于运用大数据加强对市场主体服务和监管的若干意见》，提出将建立产品信息溯源制度，对食品、药品等关系群众生命财产安全的重要产品加强监督管理，形成来源可查、去向可追、责任可究的信息链条' },
                                { name: '《全国农产品质量安全检验检测体系建设规划（年）》，该规划已经国家发展改革委审批，批复规划总投资亿元，其中，中央投资亿元，地方配套资金亿元实现农副特产品“责任主体有备案、生产过程有记录、主体责任可溯源、产品流向可追踪、监管信息可共享”' },
                                { name: '国办发〔2015〕95号文件《国务院办公厅关于加快推进重要产品追溯体系建设的意见》指出：“追溯体系建设是采集记录产品生产、流通、消费等环节信息，实现来源可查、去向可追、责任可究，强化全过程质量安全管理与风险控制的有效措施。”近年来，各地区和有关部门围绕食用农产品、食品、药品、稀土产品等重要产品，积极推动应用物联网、云计算等现代信息技术建设追溯体系，在提升企业质量管理能力、促进监管方式创新、保障消费安全等方面取得了积极成效。但是，也存在统筹规划滞后、制度标准不健全、推进机制不完善等问题。' },
                                { name: '追溯本质上并不是在追溯某个产品，而是追溯整个交易。溯源是通过交易、流转来体现价值的。在我们的平台上，从顾客掏出手机上的下单，到货物送到顾客手中，这里面经历了的所有环节，如食材的来源，使用的车辆的定位，农残检测的结果等，都可以全流程呈现。' },
                                { name: '事实上，在很多城市的大型超市中，质量安全追溯还远没有达到普及的程度。很多追溯系统的硬件已经建设完毕，登入系统后却发现，其中并没有信息，或是信息很不完善的现象时有发生。' }
                            ]
                        },
                        {
                            title: '3. 企业监管的需要',
                            data: [
                                { name: '目前的追溯体系，主要是由政府和行业建的，企业的积极性是有限的。首先，在现在的法律制度下，对建立追溯系统是持鼓励和引导的态度，而不是强制。其次，企业的信息传递不到下游，就不能对企业真正带来好处。在这一方面，政府的补贴规模、力度还较为有限。因此，解决问题的核心在于动力机制。不能为了追溯而追溯，要把它变为提升企业品牌价值的手段。' },
                                { name: '区域销售监管无序：企业对于下游销售通路的监管不力，区域经销商管理秩序混乱，存在窜货、恶意囤货现象，下游产品库存数据、销售收数据采集不准确、查询反馈不及时，区域销售管控监查无力处罚无据；' },
                                { name: '终端门店管理无方：终端门店内部管理混乱，门店库存、订货、销售以手工管理为主，管理方式落后；门店导购考勤、绩效、工资管理无据，有失公正性；区域业务人员对于门店新开、拜访、巡检、业绩等业务缺乏有效途径。' },
                                { name: '企业品牌营销乏力：品牌营销辐射能力有限，企业营销仅限于传统渠道和简单的积分方式，对于会员信息采集、互动营销与渠道拓展方面缺乏创新性的方式。' },
                            ]
                        }, {
                            title: '4. 消费者食品安全需要',
                            data: [
                                { name: '做追溯的目的是将产品信息传达给顾客，让顾客看到产品是符合标准的，是经过质量的严格管控的。目前建立追溯，更多的是出于与消费者互动的考虑，同时建立顾客信心。' },
                                { name: '产品溯源与防伪需求：随着收入水平提高，消费者对品质及安全性等有着更高的期待，而溯源和防伪是满足消费者这一心理期许最重要的方式，通过溯源与防伪消费者可以清楚的找到所关心内容并得到品质确认，获得对品质生活需求的心理满足。' },
                                { name: '多渠道购物需求：不同的情景将影响消费者对购物渠道选择，在有人际接触需要下，消费者会倾向通过线下完成购物，' },
                                { name: '急速配送需求：付款后最快时间收到产品是消费者根本需求，配送时效对购物的影响程度仅次于产品本身，尤其是在快消品行业，而单品价格越高这种需求一般也越强烈，为此消费者甚至愿意支付额外的配送费用，来满足能尽快使用的需求。' },
                                { name: '优惠与互动需求：消费者从不拒绝优惠，因为可以用更少的支出获得更多，消费者也不拒绝互动，因为互动的过程相当放松和游戏，当前消费者在获得所需产品的同时，也更加关注产品所附加的其他价值，能提供复合价值的产品也更受欢迎。' },
                            ]
                        }
                    ]
                },
                {
                    title: '3.系统解决方案',
                    data: [
                        {
                            title: '1.系统概述',
                            data: [
                                { name: '本系统的全称为“产品全程追溯与营销管理平台”，可以理解为给产品的每一件商品赋予唯一的“身份证”，以二维码为入口，运用物联网、云计算、大数据等先进技术，结合二维码可变印刷，基于“一物一码 + 互联网”构建企业大数据智慧生态圈，为商品提供从“摇篮”到“终端”的全程溯源与监管平台，以物为中心，对产品的原料辅料、生产加工、仓储物流、市场消费等环节进行数据采集跟踪，实现产品原辅料供应环节、生产环节、流通环节、销售环节、服务环节的全生命周期管理，以保证企业、用户、供应链各个环节的精准管控，响应国家提供产品质量安全的号召，构建完善的企业产品追溯体系。' },
                                { name: '使得每一个商品二维码都成为一个互联网入口，建立企业、产品、品牌、消费者之间的互联互通桥梁，形成全新型的以消费者为原点的产业生态圈，进而促成以产品为核心的企业大数据智慧生态体系，构建企业自己的大数据平台，实现产品品质管控、消费者溯源、防伪打假、渠道防窜、追溯召回、流向管理、移动营销、消费者管理、大数据应用等功能，' },
                            ]
                        },
                        {
                            title: ' 2.整体业务描述',
                            title1: '根据结合目前产品的全生命周期管理，我们将该系统划分为5个环节，分为生产赋码、仓储物流、渠道销售、会员营销、大数据分析，业务流程如下图所示：',
                            data: [
                                { name: '1. 生产赋码环节：主要解决产品一品一码的问题，实现每一件商品都有唯一的“身份证”，以“二维码”的方式展现，根据产品的规格信息，使用整箱采集技术，给每箱产品生成唯一的条码信息，实现单品码与箱码的关联关系，使用RFID无线射频技术，给每一托盘安装唯一的RFID标签，实现垛码与箱的关联关系，最终实现单品、箱、垛的三级包装关联，并且针对该批次产品进行质量检测，产生质检报告。' },
                                { name: '2. 仓储物流环节：将质检通过的产品在总仓进行生产入库，根据总仓的辐射划分，将产品分发到各级分仓，实现总仓到分仓的供货工作。各级具体配送特性的分仓，根据财务部门提供的发货单信息，将产品通过物流运输到各电商平台、经销商、分销商、终端门、消费者手中，完成产品的销售出库工作，支持各级分仓的产品调拨工作、库存盘点工作、库存预警机制等。' },
                                { name: '3. 渠道销售环节：主要负责线下的渠道管控，线上的电商平台的补货工作，以及销售渠道各级人员的管控。线下渠道管控通过采集经销商、分销商、终端门店的产品流通信息，全面掌握各级渠道的库存信息、销售情况，同时支持各个渠道的订货订单处理。线上渠道的管控，包括线上各个电商平台在各级分仓的补货工作，同时支持将消费者的订单信息导入到系统中，实现线上的销量统计工作；各个销售人员的管控，主要包括市场稽查工作、销售目标达成情况、销售人员绩效管理、销售推广活动管理等。' },
                                { name: '4. 会员营销环节：以二维码为互联网入口，实现消费者的溯源需求，提供产品的品牌形象；支持产品的防伪查询，让假货原型毕露；支持线上线下的营销互动工作，引入积分体系，让消费者主动加入生态圈，形成营销的最终闭环；支持产品的服务保障，提高消费者的消费体验，同时也能将产品问题有效反馈到企业中来，可以不断升级改良产品品质，形成良性的产品升级工作。' },
                                { name: '5. 大数据分析：系统会以产品为中心，实时记录产品信息以及在流通过程中活动信息，将企业、产品、消费者有效关联起来，构建大数据平台，为企业的业务决策提供数据支撑，告别过往的拍脑袋决策，提升企业核心竞争力。' },

                            ]
                        },
                        {
                            title: '3.整体业务流程',
                            imgUrl: 'http://www.entfly.cn/data/attachment/portal/201702/06/165949ljb6xzdtkwaa45ct.png',
                            data: [{ title: '1. 生产赋码', name: '生产赋码着眼企业产品生产的关键环节，基于可变二维码，为企业每一件产品赋予唯一的身份信息，可变二维码与传统产品条形码相比，本身信息存储量以及存储内容都有质的提升，通过产品与二维码的结合，使得产品从生产诞生开始就融入到企业整个运作经营流动中，并通过产品为纽带打通企业从生产开始到直到营销等各环节见的业务连接，搭建数据化运营与大数据的基础平台，为企业实现产品全生命周期管理打下坚实的基础。', },
                            {
                                title: '2. 仓储物流', name: '仓库管理系统是通过入库业务、出库业务、仓库调拨、库存调拨等功能，综合批次管理、库存盘点即时库存管理等功能综合运用的管理系统，有效控制并跟踪仓库业务的物流和成本管理全过程，实现完善的企业仓储信息管理。该系统可以独立执行库存操作，与其他系统的单据和凭证等结合使用，可提供更为完整全面的企业业务流程和财务管理信息。',
                                data: [
                                    {
                                        title: '1. 产品入库',
                                        name: '采购入库或者其他入库，自动生成入库单号，货品及可选择方便快捷，可以区分正常入库、退货入库等不同的入库方式。'
                                    },
                                    {
                                        title: '2. 产品出库',
                                        name: '销售出库或者其他出库，可以自动生成出库单号，可以区分正常出库、赠品出库等不同的出库方式。'
                                    },
                                    {
                                        title: '3. 库存管理',
                                        name: '不需要手工管理，当入库和出库时，系统自动生成每类产品的库存数量，查询方便。'
                                    },
                                    {
                                        title: '4. 调拨管理',
                                        name: '针对不同的库之间需要调拨，可以自动生成调拨单号，支持货品在不同的仓库中任意调拨。'
                                    },
                                    {
                                        title: '5. 盘点管理',
                                        name: '用户随时可以盘点仓库，自动生成盘点单据，使盘点工作方便快捷。'
                                    },
                                    {
                                        title: '6. 库存上限报警',
                                        name: '当库存数量不满足一个量的时候，系统自动报警。'
                                    }, {
                                        title: '7. 报表生成模块',
                                        name: '月末，季度末以及年末销售报表、采购报表以及盘点报表的自动生成功能，用户自定义需要统计的报表。'
                                    }
                                ]
                            }, {
                                title: '3. 渠道销售',
                                name: '大多数企业面临市场经销商恶性窜货的问题，造成市场倾轧、价格混乱、并严重影响厂商声誉，通过赋予每一个产品唯一的数字身份信息，企业或经销商在做出入库的时候，数字身份信息里会追加物流信息和地理位置信息，达到精确到产品个体的物流综合管理，不仅可以自动记录并进行响应预警，还可给市场稽查人员提供完整的数据判断依据，再配合消费者会员积分实现消费者查询的高概率，并能与物流码建立对应关系，有效起到主动防窜货，综合系统的维护企业和经销商的市场权益。',
                                data: [
                                    {
                                        title: '1. 防乱价',
                                        name: '通过渠道管理系统，统一管理产品价格，规划市场体系。'
                                    }, {
                                        title: '2. 防越级',
                                        name: '通渠道管理系统，统一管理经销商行为，系统自动对每个经销商业绩进行考核，评选优秀经销商并进行奖励，通过低成本、零人力便可留住经销商。'
                                    }, {
                                        title: '3. 防低价',
                                        name: '通过渠道管理系统，从根源上杜绝这些乱象发展，全过程有效管理经销商。'
                                    }, {
                                        title: '4. 防伪、防窜货',
                                        name: '用渠道管理系统，对外可以防伪，严禁不法商家批量仿冒；对内，可以防止经销商窜货，全方位维护品牌安全，保护消费者利益，为企业谋求可持续发展。'
                                    }
                                ]
                            }, {
                                title: '4. 会员营销',
                                data: [
                                    {
                                        name: '会员营销子系统涵盖了会员管理、积分营销、O2O电商、全民分、防伪查询等模块，对大多数企业而言，经销商层面是企业影响力所能达到的极限，企业对经销商下的终端和最终消费者一片空白，企业销售出大量的产品，却对终端市场和最终消费者一无所知，无法掌握了解真实的市场以及消费者情况，也就容易错失及时调整回应市场新需要新需求的机会，而通过产品二维码赋予消费者企业会员的入口，即可以让消费者获得更多好处，也可以逐步收集消费者精准数据，形成消费者习惯和行为画像，为企业在激烈复杂环境中进行精准营销和提高企业收益提供动力。'
                                    },
                                    {
                                        name: '线上O2O营销是移动互联网背景下必然的趋势和产物、以其独有的打通线上虚拟和线下实体的能力，提供精准的引导与营销服务，将店铺与线上无缝衔接通过移动互联网形成全方位多渠道环境，消费者可以选择线上购买线下兑换，也可以在线下购买再由线上的方式配送上门，以“店商+电商”融合便捷体验，互动、支付相互借力构架企业多元稳定高效的全渠道营销新通路。',
                                    },
                                    {
                                        name: '积分营销是通过灵活的积分体系来增加消费者粘性和重复购买消费，赋予每一个商品唯一的积分码，应用高效、灵活、专用的积分计算引擎，以二维码的表现形式实现消费者购买产品时同步的会员积分，通过积分抵扣消费、积分商城兑换、积分抽奖等多样积分消费方式引导消费者逐步建立对企业品牌的信赖与信任，进而提高企业产品在市场中的份额销量。企业还可以通过微博、微信、短信等方式推送活动，进行深入精准营销，通过抽奖、游戏等方式与消费者互动、增强客户活跃度。',
                                    },
                                    {
                                        name: '会员分销是一种基于朋友圈分享传播的新型营销模式。购买企业产品的消费者不仅可以享受产品本身的价值和品质，更可以让消费者成为企业的分销商，消费者拥有自己的店铺，简单的操作就可以通过朋友人际间的传播，实现企业销量提升和自身额外的收入。',
                                    },
                                    {
                                        name: '产品防伪可以为企业实现产品的亲子鉴定，通过标签或喷墨、激光等方式赋予产品唯一身份，消费者基于此可以通过扫码方式实现对产品的认证，不管产品防伪是以标签的方式还是以外包装融入的方式，因为基于唯一的二维码及和企业信息链接的方式，通过企业简单的升级就实现了极大提高假冒伪劣放置、仿造的复杂性，通过技术和成本两方面实现企业产品的防伪，打击假冒伪劣恶意仿制。',
                                    }
                                ]
                            }, {
                                title: '5. 大数据分析',
                                data: [
                                    {
                                        name: '数据分析子系统依托于其他系统在产品全生命周期中所收集的相关信息，从生产、库存、物流、销售、最终到消费者的使用，帮助企业获取到产品全生命周期各节点精准真实数据，形成一个完整的数据分析平台，通过智能引擎对信息进行相关的分类和系统处理，以报表或图形等可读性进行输出，提供立体化的数据展现方式，并且可以根据实际需要进行智能化自动的提醒、预警和多维度立体业务分析。'
                                    }, {
                                        name: '系统对库存数据进行安全库存预警，对销售数据形成动销统计，对比销售目标，形成销售目标达成数据，对企业所属分仓、经销商等形成企业版图数据地图，对会员数据形成会员画像，用营销互动不断促进会员画像的完善。'
                                    }
                                ]
                            }
                            ]
                        }
                    ]
                }
            ],
            swiperData: [
                {
                    url: 'http://www.entfly.com/data/attachment/portal/201702/07/133844kbtttm499tssihw9.jpeg',
                    name: '库存管理'
                },
                {
                    url: 'http://www.entfly.com/data/attachment/portal/201702/07/135830v1jwjssvmm7uvtv7.jpeg',
                    name: '防窜货管理'
                },
                {
                    url: 'http://www.entfly.com/data/attachment/portal/201702/07/135626up4744bi5bkp2o64.jpeg',
                    name: '数据分析预警'
                },
                {
                    url: 'http://www.entfly.com/data/attachment/portal/201702/07/135439xz4h1q3j1h3r34r4.jpeg',
                    name: 'O2O营销'
                },
                {
                    url: 'http://www.entfly.com/data/attachment/portal/201702/07/135234namqzlmm6llduz0d.jpeg',
                    name: '积分营销'
                }, {
                    url: 'http://www.entfly.com/data/attachment/portal/201702/07/134948j2xd2w668b0i60dm.jpeg',
                    name: '全民分销'
                }, {
                    url: 'http://www.entfly.com/data/attachment/portal/201702/07/134316dzf44nct1tmj302c.jpeg',
                    name: '门店管理'
                }, {
                    url: 'http://www.entfly.com/data/attachment/portal/201702/07/161854jei7ieivr4i4zvni.jpeg',
                    name: '经销商管理'
                }, {
                    url: 'http://www.entfly.com/data/attachment/portal/201702/07/102655fpchgmxopzmtgpnr.jpeg',
                    name: '原料管理'
                }, {
                    url: 'http://www.entfly.com/data/attachment/portal/201702/07/102509uvk7ivfasbp9owg9.jpeg',
                    name: '产品管理'
                }, {
                    url: 'http://www.entfly.com/data/attachment/portal/201702/07/102142v1b00519fu1dguj5.jpeg',
                    name: '生产赋码'
                }, {
                    url: '',
                    name: '防伪溯源'
                }
            ],
            CurrentName: '',

        }
    },
    components: {
        headerComponent,
        footerComponent
    },
    created() {
        this.CurrentName = this.$route.query.name;
        this.tabsUser =JSON.parse(this.$route.query.data);
        console.log(this.$route.query)
        this.currentIndex = this.$route.query.currentIndex;
    },
    mounted() {
        if (this.$route.query.name == 'powertms') {
            new Swiper(".swiper", {
                loop: false, // 循环模式选项
                initialSlide: this.currentIndex,
                navigation: {
                    nextEl: ".swiper-button-next", // 右按钮
                    prevEl: ".swiper-button-prev", // 左按钮
                },
            });
        }
    },
    methods: {
        onRouterClick(path) {
            this.$router.push({ path: path, query: { name: 'products' } })
        }
    }
}
</script>
<style scoped>
.container {
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    flex-direction: column;
    overflow: hidden;
}

.container_body {
    width: 100%;
    height: auto;
    overflow-y: scroll;
}

.products_Banner {
    width: 100%;
    min-width: 1180px;
    height: 300px;
    overflow: hidden;
    margin-top: 60px;
}

.wk_news_box {
    position: relative;
    width: 100%;
    min-width: 1180px;
    height: 300px;
    overflow: hidden;
    background: url(http://www.entfly.com/template/jiatian_jiaohu/images//vbn3.jpg) no-repeat center top;
    background-size: cover;
}

.wk_portalhead_bg {
    width: 1160px;
    padding-top: 80px;
    overflow: hidden;
    margin: 0 auto;
    text-align: center;
}

.wk_portalhead_bg ul {
    display: table;
    margin: 0 auto;
}

.wk_portalhead_bg ul li {
    float: left;
    font-size: 14px;
    display: block;
    padding: 0 20px;
    height: 32px;
    line-height: 32px;
    background: #FFF;
    margin: 0 10px;
    border-radius: 20px;
}

.wk_portalhead_bg ul li:hover,
.wk_portalhead_bg ul li.active {
    background: #DE262E;
    text-decoration: none;
    color: #FFF;
}

.wk_news_box h1 {
    text-align: center;
    color: #FFF;
    font-weight: normal;
    height: 60px;
    overflow: hidden;
    line-height: 60px;
    margin-top: 60px;
    font-size: 36px;
}

.wk_news_box p {
    text-align: center;
    color: #FFF !important;
    font-weight: normal;
    line-height: 30px;
    margin-top: 10px;
    font-size: 12px;
}

.wk_news_box p span {
    margin: 0 10px;
    color: #BBB;
}

.wk_ymbg {
    width: 100%;
    margin: 0 auto;
    padding-top: 15px;
}

.wp {
    margin: 0 auto;
    width: 1160px;
}

#wp .wp {
    width: auto;
}

#wp .wp {
    width: auto;
}

#ct {
    width: 1160px;
    min-height: 500px;
    padding-bottom: 10px;
    background: url(http://www.entfly.com/template/jiatian_jiaohu/images//portal_ft.jpg) center bottom no-repeat;
    margin: 0 auto;
}

.bm {
    border: 0px;
    background: none;
}

.bm,
.bn {
    margin-bottom: 10px;
}

.WordSection1 {
    font-family: 宋体;
    line-height: 1.8;
    width: 1160px;
}

.swiper {
    width: 100%;
    height: 100vh;
    min-width: 1200px;
    box-sizing: border-box;
    overflow: hidden;
    margin: 0 auto;
    position: relative;
    margin-bottom: 100px;
    margin-top: 40px;
}

.swiper-slide {
    transform: translate3d(0px, 0px, 0px);
    transition: all 700ms ease 0s;
    box-sizing: border-box;
}
.pipe {
    margin: 0 5px;
    color: #CCC;
}
.swiper-slide img {
    max-width: 1160px;
    margin: 0 auto;
    display: block;
}
</style>