<template>
    <div class="main-im">
        <div class="open-im" id="open-im" v-if="IsShow" @click="IsShowClick"></div>
        <div class="im_main" id="im_main" v-else>
            <div class="close_im" id="close_im" @click="IsShowClick">
                <a href="javascript:void(0)" title="点击关闭">&nbsp;</a>
            </div>
            <a href="http://wpa.qq.com/msgrd?v=3&uin=496012146&site=qq&menu=yes" title="在线QQ客服" class="im-qq qq-a">
                <div class="qq-container"></div>
                <div class="qq-hover-c">
                  <img class="img-qq" src="http://www.entfly.com/template/jiatian_jiaohu/images//service/images/qq.png" />
                </div>
                <span>QQ在线咨询</span>
            </a>
            <div class="im-tel">
                <dt>售前咨询热线</dt>
                <dt class="tel-num">18210505634</dt>
                <dt>售后服务热线</dt>
                <dt class="tel-num">4008089569</dt>
            </div>
            <div class="im-footer" style="position:relative">
                <div class="weixing-container">
                    <div class="weixing-show">
                        <div class="weixing-txt">微信扫一扫<br/>联系在线客服</div>
                        <img class="weixing-ma" src="http://www.entfly.com/template/jiatian_jiaohu/images//weixin.jpg" />
                        <div class="weixing-sanjiao"></div>
                        <div class="weixing-sanjiao-big"></div>
                    </div>
                </div>
                <div class="go-top">
                    <a href="javascript:void(0)" title="返回顶部" @click="toTopClick"></a>
                </div>
                <div style="clear:both"></div>
            </div>
        </div>
    </div>
</template>
<script>
export default{
    data(){
        return{
            IsShow:true
        }
    },
    methods:{
        IsShowClick(){
            this.IsShow = !this.IsShow
        },
        toTopClick(){
            document.querySelector(".container_body").scrollTop =0;
        }
    }
}
</script>
<style>
.main-im {
    position: fixed;
    right: 10px;
    top: 260px;
    z-index: 98;
    width: 110px;
    height: 280px;
}

.main-im .qq-a {
    display: block;
    width: 106px;
    height: 116px;
    font-size: 14px;
    color: #0484cd;
    text-align: center;
    position: relative;
}

.main-im .qq-a span {
    bottom: 5px;
    position: absolute;
    width: 90px;
    left: 10px;
}

.main-im .qq-hover-c {
    width: 70px;
    height: 70px;
    border-radius: 35px;
    position: absolute;
    left: 18px;
    top: 10px;
    overflow: hidden;
    z-index: 9;
}

.main-im .qq-container {
    z-index: 97;
    position: absolute;
    width: 109px;
    height: 118px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background: url(http://www.entfly.com/template/jiatian_jiaohu/images//service/images/qq-icon-bg.png) no-repeat center 8px;
}

.ie_all .main-im .qq-container {
    background: none;
}

.main-im .img-qq {
    max-width: 60px;
    display: block;
    position: absolute;
    left: 6px;
    top: 3px;
    -moz-transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}

.main-im .im-qq:hover .img-qq {
    max-width: 70px;
    left: 1px;
    top: 8px;
    position: absolute;
}

.main-im a img {
    border: 0 none;
}

.main-im .im_main {
    background: #F9FAFB;
    border: 1px solid #dddddd;
    border-radius: 10px;
    background: #F9FAFB;
}

.main-im .im_main .im-tel {
    color: #000000;
    text-align: center;
    width: 109px;
    height: 113px;
    padding-top: 8px;
    border-top: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
}

.main-im .im_main .im-tel dt {
    height: 24px;
    line-height: 24px;
    font-weight: bold;
    font-size: 12px;
    margin-top: 2px;
}

.main-im .im_main .im-tel .tel-num {
    font-family: Arial;
    font-weight: bold;
    color: #e66d15;
}

.main-im .im_main .im-tel:hover {
    background: #fafafa;
}

.main-im .im_main .weixing-container {
    width: 55px;
    height: 47px;
    border-right: 1px solid #dddddd;
    background: #f5f5f5;
    border-bottom-left-radius: 10px;
    background: url(http://www.entfly.com/template/jiatian_jiaohu/images//service/images/weixing-icon.png) no-repeat center center;
    float: left;
}
.main-im .im_main .weixing-container:hover .weixing-show{
   display:block
}
.main-im .im_main .weixing-show {
    width: 112px;
    height: 172px;
    background: #ffffff;
    border-radius: 10px;
    border: 1px solid #dddddd;
    position: absolute;
    left: -125px;
    top: -126px;
    display:none
}

.main-im .im_main .weixing-show .weixing-sanjiao {
    width: 0;
    height: 0;
    border-style: solid;
    border-color: transparent transparent transparent #ffffff;
    border-width: 6px;
    left: 112px;
    top: 134px;
    position: absolute;
    z-index: 2;
}

.main-im .im_main .weixing-show .weixing-sanjiao-big {
    width: 0;
    height: 0;
    border-style: solid;
    border-color: transparent transparent transparent #dddddd;
    border-width: 8px;
    left: 112px;
    top: 132px;
    position: absolute;
}

.main-im .im_main .weixing-show .weixing-ma {
    width: 104px;
    height: 103px;
    padding-left: 5px;
    padding-top: 5px;
}

.main-im .im_main .weixing-show .weixing-txt {
    position: absolute;
    top: 110px;
    left: 7px;
    width: 100px;
    margin: 0 auto;
    text-align: center;
}

.main-im .im_main .go-top {
    width: 50px;
    height: 47px;
    background: #f5f5f5;
    border-bottom-right-radius: 10px;
    background: url(http://www.entfly.com/template/jiatian_jiaohu/images//service/images/toTop-icon.png) no-repeat center center;
    float: right;
}

.main-im .im_main .go-top a {
    display: block;
    width: 52px;
    height: 47px;
}

.im_main  .close_im {
    position: absolute;
    right: 10px;
    top: -12px;
    z-index: 98;
    width: 24px;
    height: 24px;
}

.main-im .im_main  .close_im a {
    display: block;
    width: 24px;
    height: 24px;
    background: url(http://www.entfly.com/template/jiatian_jiaohu/images//service/images/close_im.png) no-repeat left top;
}

.main-im .im_main .close_im a:hover {
    text-decoration: none;
}

 .open-im {
    cursor: pointer;
    margin-left: 68px;
    width: 44px;
    height: 116px;
    background: url(http://www.entfly.com/template/jiatian_jiaohu/images//service/images/open_im.png) no-repeat left top;
}

</style>