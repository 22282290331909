import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
// import $axios from "./Utils/axios.js";
import axios from "axios";
import './assets/css/base.css'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
const api_config = require("./iAdminApi/index.js");
Vue.use(ElementUI);
Vue.prototype.$http = axios;
Vue.prototype.$api = api_config.default;
// 引入百度地图（不用再到html中导入）

import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, { ak: '162987759423e5c2af1bdc11c0f879e1' })

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')