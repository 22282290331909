<template>
    <div class="container">
        <div class="container_body">
            <header-component :status="true"></header-component>
            <div class="products_Banner">
                <img :src="BannerUrl" alt="" srcset="">
            </div>
            <div class="content w_100 box_size">
                <div class="content_tabs">
                    <el-tabs v-model="activeName" @tab-click="handleClick" class="contents-el-tabs">
                        <el-tab-pane label="全部" name="/productsIndex"></el-tab-pane>
                        <el-tab-pane label="防伪溯源系统" name="/productspowertms"></el-tab-pane>
                        <el-tab-pane label="微溯云" name="/productsweisu"></el-tab-pane>
                        <el-tab-pane label="品牌助手" name="/productsbrand"></el-tab-pane>
                        <el-tab-pane label="微控宝" name="/productswkb"></el-tab-pane>
                    </el-tabs>
                </div>
                <div class="tabsList_body ">
                    <div class="tabsList" v-if="tableData.length > 0">
                        <div class="items displayCenterCenter" v-for="(items, index) in tableData" :key="index" @click="onClick(items.path)">
                            <div class="BJ_images">
                                <img :src="items.bj_url" />
                            </div>
                            <div class="wk_products_ritbox">
                                <div class="wk_products_name">
                                    <div class="wk_products_name2">微溯云平台</div>
                                </div>
                                <div class="wk_products_nrbox2">
                                    {{ items.content }}
                                    <span>{{ items.date }}</span>
                                </div>
                            </div>
                            <div class="wk_products_lbmore"></div>
                        </div>
                    </div>
                    <div class="pagination w_100 displayCenterCenter mtb20" v-if="tableData.length > 0">
                        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                            :current-page="currentPage" :page-sizes="pageSizes" :page-size="pageSize"
                            layout="total, prev, pager, next" :total="total" background>
                        </el-pagination>
                    </div>
                </div>
            </div>
            <footerComponent></footerComponent>
        </div>
    </div>
</template>
<script>
import headerComponent from '../../components/comFrom/Header.vue'
import parmasData from '../../public/static/products.json'
import footerComponent from '../../components/comFrom/footer.vue'
export default {
    name: 'products',
    data() {
        return {
            BannerUrl: 'http://www.entfly.com/template/jiatian_jiaohu/images/bn6.jpg',
            activeName: '/productsweisu',
            currentPage: 1, //当前页
            total: 0, //总条数
            list: [], //后台返回的所有结果
            tableData: [], //当前页码的表格数据
            pageSize: 10, //当前页容量
            pageSizes: [10, 20, 30, 40, 50],
        }
    },
    components: {
        headerComponent,
        footerComponent
    },
    mounted() {
        console.log(parmasData, 'parmasData')
        this.list = parmasData.data.weisu.list;    //总数组
        this.total = parmasData.data.weisu.total;      //总条数
        this.BannerUrl = parmasData.data.weisu.background;
        this.getList()
    },
    methods: {
        handleClick(tab, event) {
            this.activeName = tab._props.name;
            this.$router.push({ path: tab._props.name, query: { name: 'products' } })
        },
        onClick(path){
            this.$router.push({ path: path,query: { name: 'weisu' }})
        },
        //切换每页条数容量
        handleSizeChange(val) {
            this.currentPage = this.currentPage + 1;
            console.log(this.list, this.total, this.currentPage)
            this.getList();
        },
        //切换当前页
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getList();
        },
        //获取表格数据
        getList() {
            // getList().then((res) => {
            this.list = this.list;    //总数组
            this.total = this.total;      //总条数
            this.tableData = this.getNeedArr(this.list, this.pageSize)[this.currentPage - 1]  //当前页的表格数据
            // });
        },
        // 前端处理分页
        getNeedArr(array, size) {       //获取所需指定长度分割的数组;参数1为用于分割的总数组,参数2为分割数组后每个小数组的长度
            const length = array.length;
            //判断不是数组，或者size没有设置，size小于1，就返回空数组
            if (!length || !size || size < 1) {
                return [];
            }

            let index = 0; //用来表示切割元素的范围start
            let resIndex = 0; //用来递增表示输出数组的下标

            //根据length和size算出输出数组的长度，并且创建它。
            let result = new Array(Math.ceil(length / size));
            //进行循环
            while (index < length) {
                //循环过程中设置result[0]和result[1]的值。该值根据array.slice切割得到。
                result[resIndex++] = array.slice(index, (index += size));
            }
            //输出到新数组
            return result;
        },
    }
}
</script>
<style scoped>
.container {
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    flex-direction: column;
    overflow: hidden;
}

.container_body {
    width: 100%;
    height: auto;
    overflow-y: scroll;
}

.products_Banner {
    width: 100%;
    min-width: 1180px;
    height: 300px;
    overflow: hidden;
    margin-top: 60px;
}

.products_Banner img {
    width: 100%;
    height: 100%;
}


.content_tabs {
    width: 100%;
    height: 65px;
    background: url(http://www.entfly.com/template/jiatian_jiaohu/images//about_line.jpg) repeat-x left bottom;
    overflow: hidden;
}

.contents-el-tabs {
    width: 500px;
    height: 65px;
    line-height: 65px;
    margin: 0 auto;
    font-size: 16px;
}

.tabsList_body {
    width: 1190px;
    margin: 0 auto;
}

.tabsList {
    width: 100%;
    min-height: 350px;
    padding-bottom: 10px;
    background: url(http://www.entfly.com/template/jiatian_jiaohu/images//portal_ft.jpg) center bottom no-repeat;
}

.tabsList_body .tabsList .items {
    background-size: cover;
    width: 100%;
    height: 262px;
    margin: 15px;
    position: relative;
    overflow: hidden;
    border-bottom: 1px #f0f0f0 solid;
}

.BJ_images {
    width: 295px;
    height: 200px;
}

.tabsList .items .BJ_images img {
    width: 100%;
    height: 180px;
}

.wk_products_ritbox {
    width: 865px;
    position: relative
}

.wk_products_name {
    width: 600px;
    height: 30px;
    line-height: 30px;
    overflow: hidden;
    font-size: 20px;
    margin-top: 10px;
}

.wk_products_name a {
    color: #666;
    font-weight: bold;
}

.wk_products_nrbox2 {
    width: 600px;
    height: 112px;
    line-height: 28px;
    font-size: 14px;
    color: #666;
    margin-top: 15px;
    display: block;
}

.wk_products_nrbox2 .xg1 {
    font-size: 14px;
    line-height: 28px;
    margin-top: 10px;
}
.wk_products_lbmore{
    width: 219px;
    height: 45px;
    text-indent: -9999px;
    display: block;
    background: url(http://www.entfly.com/template/jiatian_jiaohu/images//pro_button.png) no-repeat center;
}
</style>
<style>
.el-tabs__nav-wrap::after {
    background: transparent !important;
}

.el-tabs__item {
    font-size: 16px !important;
}

.el-tabs__item.is-active {
    color: #DE262E !important;
}

.el-tabs__active-bar {
    background-color: #DE262E !important;
}

.el-tabs__item:hover {
    color: #DE262E !important;
}</style>