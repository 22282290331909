<template>
    <div class="gsxw_body">
        <div class="wk_c_right_name displayBetweenCenter">
            <div class="w_80 displayCenter">
                <el-image style="width: 19px; height: 19px"
                    src="http://www.entfly.com/template/jiatian_jiaohu/images//portal/right_wz.png"></el-image>
                <span class="plr10">您现在的位置：</span>
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item>首页</el-breadcrumb-item>
                    <el-breadcrumb-item>新闻动态</el-breadcrumb-item>
                    <el-breadcrumb-item>{{weisuData.breadcrumb[0]}}</el-breadcrumb-item>
                    <el-breadcrumb-item>{{weisuData.breadcrumb[1]}}</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <p class="wk_c_right_name_l2">查看内容</p>
        </div>
        <div class="gsxw_list">
            <div class="WordSection1">
                <p class="t_center size20 mt20" style="color: #999;">{{ weisuData.title }}</p>
                <p class="xg1 t_center mt20 size12 mb20" style="color: #999;">{{ weisuData.date }}<span class="pipe">|</span>发布者: {{ weisuData.admin }}<span
                        class="pipe">|</span>查看:<em id="_viewnum">{{ weisuData.num }}</em><span class="pipe">|</span>评论:
                    {{ weisuData.count }}</p>
                <div class="w_85" style="margin: 0 auto;" v-for="(itemss, indexs) in weisuData.data" :key="indexs">
                    <h5 class="mb20 ml20" v-if="itemss.title">{{ itemss.title }}</h5>
                    <p class="size14 mtb20" style="line-height: 30px;">{{ itemss.name }}</p>
                    <img class="w_100" v-if="itemss.imgUrl" :src="itemss.imgUrl" alt="" srcset="">
                    <div v-for="(itemsss, indexss) in itemss.data" :key="indexss" style="text-indent: 2em;" class="mtb20">{{
                        itemsss.name }}
                        <h5>{{ itemsss.title }}</h5>
                        <div v-for="(itemssss, indexsss) in itemsss.data" :key="indexsss" style="text-indent: 2em;">
                            <h4>{{ itemssss.title }}</h4>
                            <div>{{ itemssss.name }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import data from '../../public/static/newsUpdates.json'
export default {
    data() {
        return {
            weisuData: {}
        }
    },
    mounted() {
        if (this.$route.query.name == 'gsxw') {
            this.weisuData = data.data.GsxwDetail;
        }else if(this.$route.query.name =='hyxw'){
            this.weisuData = data.data.HyxwDetail
        }else if(this.$router.app._route.query.name == "mtbt"){
            this.weisuData = data.data.mtbtDetail[this.$router.app._route.query.index]
        }
    }
}
</script>
<style scoped>
.wk_c_right_name {
    height: 55px;
    position: relative;
    border-bottom: 1px solid #E6E6E6;
    color: #999;
    font-size: 12px !important;
}

.gsxw_main {
    height: 179px;
    position: relative;
    border-bottom: 1px dashed #E6E6E6;
}

.gsxw_main-right {
    width: calc(100% - 257px);
    margin-left: 20px;
    cursor: pointer;
}

.gsxw_main-right h5 {
    width: 595px;
    color: #DE262E;
    height: 44px;
    line-height: 44px;
    font-size: 18px;
    border-bottom: 1px dashed #dedede;
    white-space: nowrap;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    overflow: hidden
}

.gsxw_main-right:hover h5 {
    color: #666;
}

.gsxw_main-right p {
    width: 595px;
    line-height: 24px;
    height: 85px;
    display: block;
    overflow: hidden;
    font-size: 12px;
    color: #666666;
}

.gsxw_main-right span {
    color: #666;
    font-size: 12px;
    line-height: 20px;
    width: 595px;
    cursor: pointer;
}

.wk_c_right_name_l2 {
    color: #333;
    font-size: 16px;
    border-bottom: 2px solid #DE262E;
    height: 55px;
    line-height: 55px;
}
.pipe {
    margin: 0 5px;
    color: #CCC;
}
.WordSection1 h5:first-child{
    /* margin-top: 50px; */
}
</style>
<style>
.wk_c_right_name .el-breadcrumb {
    font-size: 12px;
}
</style>